/** @format */

const goodHealthCertificateData = {
  content: {
    data: `This is to certify that I have examined [Patient’s Full Name] on [date] and based on my assessment, they are in good physical and mental health. At the time of this examination, there is no evidence of any medical condition that would impair their ability to carry out routine activities or specific tasks required for [Good Health Tasks]. `,
  },
  goodHealthTasks: [
    "Visa Applications",
    "Employment",
    "Academic Enrollments",
    "Travel",
    "Residency Applications",
  ],
};

export default goodHealthCertificateData;
