/** @format */

export default {
  setMedicalLettersRequests(state, payload) {
    state.medicalLetterRequests = payload;
  },
  setMedicalLetterRequest(state, payload) {
    state.medicalLetterRequest = payload;
  },
  setSignature(state, payload) {
    state.signature = payload;
  },
  updateSignature(state, payload) {
    state.signature = [payload, ...state.signature];
  },
  editSignature(state, payload) {
    state.signature.splice(payload.signatureId, 1, payload.response);
  },
  setDoctorsProfile(state, payload) {
    state.doctorsProfile = payload;
  },
  setDoctorProfile(state, payload) {
    state.doctorProfile = payload;
  },
};
