<!-- @format -->

<template>
  <div class="border border-gray-300 rounded-lg mb-6 bg-white">
    <h2 class="text-lg font-medium px-6 py-4">Patient Information</h2>
    <div
      v-if="props.isLoading"
      class="grid grid-cols-3 gap-4 border-t border-l-0 border-r-0 border-b-0 border border-gray-200 p-6 animate-pulse"
    >
      <div v-for="n in 10" :key="n">
        <div class="h-6 bg-gray-300 rounded-md mb-4 w-32"></div>
        <div class="h-12 bg-gray-300 rounded-md"></div>
      </div>
    </div>
    <div
      class="grid grid-cols-3 gap-4 border-t border-l-0 border-r-0 border-b-0 border border-gray-200 p-6"
      v-else
    >
      <div>
        <label class="block font-medium text-gray-700 mb-3">First Name</label>
        <div
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500 capitalize"
        >
          {{ localPatient.first_name }}
        </div>
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Last Name</label>
        <div
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500 capitalize"
        >
          {{ localPatient.last_name }}
        </div>
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Phone Number</label>
        <input
          v-model="localPatient.phone"
          type="text"
          disabled="true"
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Email</label>
        <input
          v-model="localPatient.email"
          type="email"
          disabled="true"
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Address</label>
        <input
          v-model="localPatient.address"
          type="text"
          disabled="true"
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">City</label>
        <div
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500 capitalize"
        >
          {{ localPatient.city }}
        </div>
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Country</label>
        <div
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500 capitalize"
        >
          {{ localPatient.country }}
        </div>
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Zip Code</label>
        <input
          v-model="localPatient.zipcode"
          type="text"
          disabled="true"
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3"
          >Date of Birth</label
        >
        <input
          v-model="localPatient.dob"
          type="date"
          disabled="true"
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-700 mb-3">Gender</label>
        <div
          class="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-teal bg-opacity-10 text-gray-500 capitalize"
        >
          {{ localPatient.gender }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { defineProps } from "vue";

const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
  },
});

const localPatient = ref({ ...props.patient });

watch(
  () => props.patient,
  (newVal) => {
    localPatient.value = { ...newVal };
  }
);
</script>
