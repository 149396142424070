<!-- @format -->

<template>
  <div :class="formClass">
    <div class="flex gap-4">
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3">First Name</label>
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <input
          v-else
          v-model="doctor.first_name"
          @input="updateFirstName($event.target.value)"
          maxlength="25"
          type="text"
          class="mt-1 block w-full p-2.5 border border-gray-300 rounded-sm text-gray-500"
          :class="{
            'bg-teal bg-opacity-10 w-full': isPreview,
            'bg-white': !isPreview,
          }"
          :disabled="isPreview"
        />
        <span class="text-red-500 mt-1">{{ errors.firstName }}</span>
      </div>
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3">Last Name</label>
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <input
          v-else
          v-model="doctor.last_name"
          @input="updateLastName($event.target.value)"
          maxlength="25"
          type="text"
          class="mt-1 block w-full p-2.5 border border-gray-300 rounded-sm text-gray-500"
          :class="{
            'bg-teal bg-opacity-10': isPreview,
            'bg-white': !isPreview,
          }"
          :disabled="isPreview"
        />
        <span class="text-red-500 mt-1">{{ errors.lastName }}</span>
      </div>
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3"
          >GMC Registration Number</label
        >
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <input
          v-else
          v-model="doctor.gmc_number"
          @input="updateGmcRegistrationNumber($event.target.value)"
          maxlength="25"
          type="text"
          class="mt-1 block w-full p-2.5 border border-gray-300 rounded-sm text-gray-500"
          :class="{
            'bg-teal bg-opacity-10': isPreview,
            'bg-white': !isPreview,
          }"
          :disabled="isPreview"
        />
        <span class="text-red-500 mt-1">{{
          errors.gmcRegistrationNumber
        }}</span>
      </div>
    </div>
    <div class="flex gap-4 mt-4">
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3"
          >GMC Registration Status</label
        >
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <v-select
          v-else
          v-model="selectedStatus"
          label="name"
          placeholder="Select Status"
          :clearable="false"
          :options="status"
          @update:modelValue="choosenStatus"
        ></v-select>

        <span class="text-red-500 mt-1">{{ errors.status }}</span>
      </div>
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3"
          >Doctor qualification</label
        >
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <input
          v-else
          v-model="doctor.doctor_qualification"
          @input="updateQualification($event.target.value)"
          maxlength="50"
          type="text"
          class="mt-1 block w-full p-2.5 border border-gray-300 rounded-sm text-gray-500"
          :class="{
            'bg-teal bg-opacity-10': isPreview,
            'bg-white': !isPreview,
          }"
          :disabled="isPreview"
        />
        <span class="text-red-500 mt-1">{{ errors.qualification }}</span>
      </div>
      <div class="w-full">
        <label class="block font-medium text-gray-700 mb-3"
          >Doctor Practice</label
        >
        <div
          v-if="isLoading"
          class="h-14 bg-gray-200 animate-pulse rounded-sm"
        ></div>
        <template v-else>
          <v-select
            v-if="!isPreview"
            v-model="practiceName"
            label="practice_name"
            placeholder="Select Practice"
            :options="practices"
            @update:modelValue="choosenPractice"
          ></v-select>
          <div
            v-else
            class="border border-gray-200 py-2.5 text-gray-500 px-2 rounded isEdit"
          >
            {{ practiceName }}
          </div>
        </template>
        <span class="text-red-500 mt-1">{{ errors.practice }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, toRefs, defineEmits, computed, watch } from "vue";

const props = defineProps({
  practices: {
    type: Array,
    required: true,
  },
  status: {
    type: Array,
    required: true,
  },
  isPreview: {
    type: Boolean,
    required: true,
  },
  doctorProfile: {
    type: Object,
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { practices, status, isPreview, doctorProfile, isLoading } =
  toRefs(props);

const doctor = ref({
  first_name: "",
  last_name: "",
  gmc_number: "",
  doctor_qualification: "",
  practice: "",
  gmc_status: "",
});

watch(
  doctorProfile,
  (newDoctor) => {
    if (isPreview.value) {
      doctor.value.first_name = newDoctor.first_name;
      doctor.value.last_name = newDoctor.last_name; // Added last name assignment
      doctor.value.gmc_number = newDoctor.gmc_number; // Added GMC number assignment
      doctor.value.doctor_qualification = newDoctor.doctor_qualification; // Added qualification assignment
      practiceName.value = newDoctor.practice?.practice_name; // Added practice assignment
      selectedStatus.value = newDoctor.gmc_status; // Added GMC status assignment
      // ... existing code ...
    }
  },
  { deep: true }
);

const selectedPracticeId = ref();
const selectedStatus = ref();
const practiceName = ref("");
const statusName = ref("");

const errors = ref({
  firstName: "",
  lastName: "",
  gmcRegistrationNumber: "",
  gmcRegistrationStatus: "",
  qualification: "",
  practice: "",
});

const emit = defineEmits(["on-field-change"]);

const handleFieldChange = (fieldName, value) => {
  switch (fieldName) {
    case "first_name":
      errors.value.firstName = value ? "" : "First name is required.";
      break;
    case "last_name":
      errors.value.lastName = value ? "" : "Last name is required.";
      break;
    case "gmc_number":
      errors.value.gmcRegistrationNumber = value
        ? ""
        : "GMC Registration Number is required.";
      break;
    case "doctor_qualification":
      errors.value.qualification = value ? "" : "Qualification is required.";
      break;
    case "practice":
      errors.value.practice = practiceName.value ? "" : "Practice is required.";
      break;
    case "gmc_status":
      errors.value.status = selectedStatus.value ? "" : "Status is required.";
      break;
  }

  emit("on-field-change", { fieldName, value });
};

const updateFirstName = (value) => {
  doctor.value.first_name = value;
  handleFieldChange("first_name", value);
};

const updateLastName = (value) => {
  doctor.value.last_name = value;
  handleFieldChange("last_name", value);
};

const updateGmcRegistrationNumber = (value) => {
  doctor.value.gmc_number = value;
  handleFieldChange("gmc_number", value);
};

const updateQualification = (value) => {
  doctor.value.doctor_qualification = value;
  handleFieldChange("doctor_qualification", value);
};

const choosenPractice = (practice) => {
  errors.value.selectedPracticeId = "";
  if (practice) {
    selectedPracticeId.value = practice?.id;
    handleFieldChange("practice", practice?.id);
    practiceName.value = practice.practice_name;
  } else selectedPracticeId.value = null;
};

const choosenStatus = (status) => {
  errors.value.selectedStatus = "";
  if (status) {
    selectedStatus.value = status.name;
    handleFieldChange("gmc_status", status.name);
    statusName.value = status.name;
  } else selectedStatus.value = null;
};

const isFormValid = computed(() => {
  const valid =
    doctor.value.first_name &&
    doctor.value.last_name &&
    doctor.value.gmc_number &&
    doctor.value.doctor_qualification &&
    practiceName.value &&
    selectedStatus.value &&
    (isPreview.value ? (statusName.value ? true : false) : true); // Check GMC status if in preview
  emit("form-validity-change", valid);
  return valid;
});

const formClass = computed(() =>
  isFormValid.value ? "valid-form" : "invalid-form"
);
</script>
<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 7px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
.swal2-cancel.swal2-styled {
  border-color: red !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url("../../../assets/icons/calendar_month.svg");
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}

input[type="radio"]#is-visible {
  accent-color: teal;
}

.isEdit {
  background: rgba(231, 243, 242, 0.65);
}
</style>
