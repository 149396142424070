/** @format */

const housingSupportData = {
  content: {
    data: `I am writing to support [Patient’s Full Name] in relation to their current housing situation. [Patient’s Full Name] has been under my care for [Housing Support Conditions], which is significantly impacted by their living environment.
It is my professional opinion that [Patient’s Full Name] requires housing that meets the following criteria: 
    [Housing Support Requirement 1, e.g., ground floor accommodation due to mobility issues] 
    [Housing Support Requirement 2, e.g., absence of damp or mould due to respiratory issues] 
    [Housing Support Requirement 3, e.g., proximity to essential healthcare facilities]. 
    The current living conditions are exacerbating [Patient’s Full Name]’s health condition and I strongly recommend that their housing situation is reassessed as a matter of urgency.`,
  },
  conditions: {
    respiratoryConditions: [
      "Asthma or Chronic Obstructive Pulmonary Disease (COPD): Living in damp or moldy environments can exacerbate these conditions, necessitating relocation to a drier, well-ventilated home.",
    ],
    mobilityImpairments: [
      "Arthritis or Physical Disabilities: Individuals with limited mobility may require ground-floor accommodations or properties with wheelchair access to navigate their living space safely.",
    ],
    mentalHealthDisorders: [
      "Severe Anxiety or Depression: Overcrowded or noisy living conditions can worsen mental health, making a quieter, more private residence beneficial.",
    ],
    neurologicalConditions: [
      "Epilepsy: Unsafe housing environments, such as those with steep stairs or lack of safety features, can pose significant risks during seizures, indicating a need for safer housing.",
    ],
    sensoryImpairments: [
      "Visual or Hearing Impairments: Individuals may require specific housing adaptations, like improved lighting or soundproofing, to accommodate their sensory needs.",
    ],
    chronicIllnesses: [
      "Cancer or Renal Disease: Patients undergoing treatments that compromise their immune system may need housing away from communal living spaces to reduce infection risks.",
    ],
    developmentalDisorders: [
      "Autism Spectrum Disorder: Individuals may benefit from a structured and calm environment, which might not be possible in shared or unsuitable housing.",
    ],
  },
  requirements: {
    accessibilityFeatures: [
      "Ground-floor accommodation or a property with elevator access.",
      "Wheelchair-accessible features, such as wide doorways and step-free entrances.",
      "Bathroom adaptations (e.g., walk-in showers, grab rails, or raised toilet seats).",
    ],
    environmentalConditions: [
      "A dry, well-ventilated property free from damp and mold to prevent exacerbation of respiratory conditions (e.g., asthma, COPD).",
      "A quiet environment to reduce mental health stressors such as severe anxiety or depression.",
      "Adequate insulation and heating to mitigate the effects of chronic illnesses or mobility impairments.",
    ],
    proximityToServices: [
      "Close access to healthcare facilities, such as GP surgeries, hospitals, or specialist clinics.",
      "Easy access to public transport for attending medical appointments or therapy sessions.",
      "Close proximity to carers, family, or support networks for daily assistance.",
    ],
    spaceRequirements: [
      "Adequate space for medical equipment (e.g., oxygen tanks, mobility aids).",
      "Separate rooms for live-in carers or family members providing support.",
    ],
    safetyConsiderations: [
      "No steep stairs or trip hazards to prevent falls, especially for those with balance or mobility impairments.",
      "Secure and well-lit surroundings for patients with sensory impairments or mental health conditions.",
    ],
    specificModifications: [
      "Adaptations to accommodate sensory sensitivities, such as soundproofing for individuals with autism spectrum disorders.",
      "Specialized lighting or tactile indicators for visually impaired patients.",
    ],
  },
};

export default housingSupportData;
