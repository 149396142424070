/** @format */

const workSicknessConditionsData = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] has been under my care and is unfit for work from [start date] to [end date] due to [Work Sickness Condition]. `,
  },
  workSicknessConditions: {
    condition: [
      "Acute Illnesses",
      "Chronic Conditions",
      "Mental Health Disorders",
      "Musculoskeletal Disorders",
      "Postoperative Recovery",
      "Injuries",
      "Pregnancy-Related Conditions",
      "Contagious Diseases",
      "Neurological Conditions",
      "Autoimmune Disorders",
    ],
  },
};

export default workSicknessConditionsData;
