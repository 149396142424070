/** @format */

const travelAndHolidayCancellationConditionsData = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] has been under my care and is unable to proceed with their planned travel due to [Travel & Holiday Cancellation Conditions]. Their medical condition was first diagnosed on [date], and I have advised that they refrain from travelling from [start date] to [end date] to prioritise their health and recovery. This decision is based on clinical evidence and is necessary to prevent any potential exacerbation of their condition. This certificate is provided to support their request for cancellation of travel or holiday arrangements.`,
  },
  travelAndHolidayCancellation: {
    Conditions: [
      "Acute Illnesses",
      "Chronic Conditions",
      "Injuries",
      "Mental Health Issues",
      "Pregnancy Complications",
      "Postoperative Recovery",
      "Contagious Diseases",
    ],
  },
};

export default travelAndHolidayCancellationConditionsData;
