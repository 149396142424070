<!-- @format -->

<template>
  <div
    class="p-10 space-y-24 border border-gray-300 rounded-md shadow-md h-full animate-pulse"
  >
    <div class="flex justify-between items-center">
      <div class="w-1/3 h-8 bg-gray-300 rounded"></div>
      <div class="w-44 h-16 bg-gray-300 rounded"></div>
    </div>

    <div class="space-y-8">
      <div class="grid grid-cols-2 gap-4">
        <div class="space-y-2">
          <div class="w-2/3 h-4 bg-gray-300 rounded"></div>
          <div class="w-full h-4 bg-gray-300 rounded"></div>
          <div class="w-1/2 h-4 bg-gray-300 rounded"></div>
        </div>
        <div class="space-y-2">
          <div class="w-1/2 h-4 bg-gray-300 rounded"></div>
          <div class="w-full h-4 bg-gray-300 rounded"></div>
          <div class="w-2/3 h-4 bg-gray-300 rounded"></div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div class="space-y-2">
          <div class="w-full h-4 bg-gray-300 rounded"></div>
          <div class="w-1/2 h-4 bg-gray-300 rounded"></div>
        </div>

        <div class="space-y-2">
          <div class="w-2/3 h-4 bg-gray-300 rounded"></div>
          <div class="w-1/2 h-4 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>

    <div class="space-y-4">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
      <div class="w-3/4 h-4 bg-gray-300 rounded"></div>
      <div class="w-1/2 h-4 bg-gray-300 rounded"></div>
    </div>

    <div class="space-y-2">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
      <div class="w-1/3 h-4 bg-gray-300 rounded"></div>
    </div>

    <div class="space-y-2">
      <div class="flex justify-between items-center">
        <div class="w-1/4 h-4 bg-gray-300 rounded"></div>
        <div class="w-1/4 h-4 bg-gray-300 rounded"></div>
      </div>
      <div class="flex justify-between items-center">
        <div class="w-1/4 h-4 bg-gray-300 rounded"></div>
        <div class="w-1/4 h-4 bg-gray-300 rounded"></div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
