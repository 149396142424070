/** @format */

const injuryAndAccidentData = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] attended my clinic on [date] following an accident that occurred on [date of the accident].
Upon assessment, they were found to have sustained the following injuries: 
    [Injury And Accident Injury 1, e.g., fractured left wrist] 
    [Injury And Accident Injury 2, e.g., soft tissue damage to the right ankle] 
    [Injury And Accident Injury 3, e.g., soft tissue damage to the right ankle] 
    [Patient’s Full Name] has been advised to [Injury And Accident Instructions] for a period of [Injury And Accident Duration]. This letter is provided to confirm the nature of the injuries and to support [Injury And Accident Purpose]. `,
  },
  injuryinstruction: {
    musculoskeletalInjuries: {
      fractures: {
        instructions: [
          "Follow immobilization guidelines (e.g., wearing a cast or splint as directed).",
          "Avoid putting weight on the injured area until advised by a healthcare professional.",
          "Engage in physiotherapy exercises once the bone starts healing to restore strength and mobility.",
          "Take prescribed pain relief medications as needed.",
        ],
      },
      sprainsAndStrains: {
        instructions: [
          "Use the RICE Method:",
          "Rest: Avoid activities that put stress on the injured area.",
          "Ice: Apply an ice pack for 15–20 minutes every few hours to reduce swelling.",
          "Compression: Use an elastic bandage to provide support.",
          "Elevation: Keep the injured limb raised above heart level when possible.",
          "Gradually reintroduce movement under guidance from a physiotherapist.",
        ],
      },
      dislocations: {
        instructions: [
          "Avoid moving the affected joint until it is properly treated.",
          "Wear a sling or brace if prescribed.",
          "Schedule follow-up appointments to monitor healing and prevent re-dislocation.",
        ],
      },
      whiplash: {
        instructions: [
          "Use over-the-counter pain relief (e.g., ibuprofen or paracetamol).",
          "Apply hot or cold compresses to the neck.",
          "Perform gentle neck stretches and strengthening exercises as advised by a physiotherapist.",
        ],
      },
    },
    softTissueInjuries: {
      bruises: {
        instructions: [
          "Apply an ice pack to reduce swelling.",
          "Rest the area to allow for natural healing.",
          "Use over-the-counter pain relievers if needed.",
        ],
      },
      lacerationsAndCuts: {
        instructions: [
          "Keep the wound clean and dry to prevent infection.",
          "Apply antibiotic ointment and change dressings as directed.",
          "Watch for signs of infection (e.g., redness, swelling, pus) and seek medical attention if they occur.",
        ],
      },
      tendonOrLigamentTears: {
        instructions: [
          "Rest and immobilize the area as prescribed.",
          "Gradually engage in physiotherapy to regain strength and flexibility.",
          "Surgery may be necessary in severe cases; follow post-operative recovery instructions carefully.",
        ],
      },
    },
    headInjuries: {
      concussions: {
        instructions: [
          "Rest completely for 24–48 hours and avoid screens, reading, or strenuous mental activity.",
          "Gradually return to normal activities while monitoring symptoms.",
          "Seek urgent medical attention if symptoms worsen (e.g., persistent vomiting, confusion, or severe headache).",
        ],
      },
      scalpLacerations: {
        instructions: [
          "Keep the wound clean and covered.",
          "Avoid touching or scratching the area to prevent infection.",
          "Follow suture or staple removal timelines if applicable.",
        ],
      },
    },
    backAndSpinalInjuries: {
      herniatedDiscs: {
        instructions: [
          "Avoid heavy lifting or twisting movements.",
          "Engage in gentle stretching and strengthening exercises as prescribed by a physiotherapist.",
          "Consider pain management options, including medication or epidural injections.",
        ],
      },
      spinalFractures: {
        instructions: [
          "Rest in a position that minimizes pain and follow prescribed movement restrictions.",
          "Wear a back brace if recommended.",
          "Attend follow-up appointments to monitor healing progress.",
        ],
      },
    },
    burnsAndScalds: {
      thermalBurns: {
        instructions: [
          "Cool the burn with lukewarm running water for at least 10 minutes.",
          "Avoid applying ice or greasy substances (e.g., butter).",
          "Cover with a sterile, non-stick dressing.",
          "Attend regular wound care check-ups for severe burns.",
        ],
      },
      chemicalBurns: {
        instructions: [
          "Rinse the affected area with cool water for at least 20 minutes.",
          "Remove contaminated clothing carefully.",
          "Follow specific decontamination and treatment plans provided by the GP or hospital.",
        ],
      },
    },
    eyeInjuries: {
      cornealAbrasions: {
        instructions: [
          "Avoid rubbing the eye.",
          "Use prescribed antibiotic eye drops or ointments.",
          "Wear sunglasses to protect the eye from bright light.",
        ],
      },
      foreignBodies: {
        instructions: [
          "Rinse the eye gently with sterile saline or clean water.",
          "Do not attempt to remove embedded objects yourself; seek medical attention.",
        ],
      },
    },
    psychologicalImpact: {
      acuteStressReaction: {
        instructions: [
          "Rest and take time to recover emotionally.",
          "Engage in relaxation techniques, such as deep breathing or meditation.",
          "Seek counseling or support if symptoms persist.",
        ],
      },
      ptsd: {
        instructions: [
          "Follow a treatment plan, including therapy (e.g., Cognitive Behavioral Therapy).",
          "Engage in stress-reducing activities such as yoga or mindfulness.",
          "Avoid triggers that worsen symptoms and consider joining support groups.",
        ],
      },
    },
  },
  injuryTypes: {
    musculoskeletalInjuries: {
      fractures: {
        description: "Broken bones in the arms, legs, ribs, etc.",
      },
      sprainsAndStrains: {
        description:
          "Injuries to ligaments (sprains) or muscles/tendons (strains).",
      },
      dislocations: {
        description: "Joints forced out of their normal positions.",
      },
      whiplash: {
        description:
          "Neck injury due to rapid back-and-forth movement, commonly from car accidents.",
      },
    },
    softTissueInjuries: {
      bruises: {
        description: "Blood vessels damaged under the skin.",
      },
      lacerationsAndCuts: {
        description: "Tears or openings in the skin.",
      },
      tendonOrLigamentTears: {
        description:
          "Tears in tendons or ligaments, such as Achilles tendon rupture.",
      },
    },
    headInjuries: {
      concussions: {
        description: "Mild traumatic brain injuries affecting brain function.",
      },
      scalpLacerations: {
        description: "Cuts on the head.",
      },
    },
    backAndSpinalInjuries: {
      herniatedDiscs: {
        description: "Discs in the spine pressing on nerves.",
      },
      spinalFractures: {
        description: "Breaks in the vertebrae.",
      },
    },
    burnsAndScalds: {
      thermalBurns: {
        description: "Injuries from contact with hot objects or flames.",
      },
      chemicalBurns: {
        description: "Injuries from corrosive substances.",
      },
    },
    eyeInjuries: {
      cornealAbrasions: {
        description: "Scratches on the eye's surface.",
      },
      foreignBodies: {
        description: "Objects lodged in the eye.",
      },
    },
    psychologicalImpact: {
      acuteStressReaction: {
        description:
          "Immediate psychological response following a traumatic event.",
      },
      ptsd: {
        description: "Developing after exposure to a traumatic event.",
      },
    },
  },
  generalRecoveryRecommendations: {
    dietAndHydration: {
      instructions: [
        "Maintain a balanced diet rich in protein, vitamins, and minerals to support tissue repair.",
        "Stay hydrated to aid overall recovery.",
      ],
    },
    followUpCare: {
      instructions: [
        "Attend all scheduled follow-up appointments with your GP or specialists.",
        "Report any worsening symptoms promptly.",
      ],
    },
    gradualResumptionOfActivities: {
      instructions: [
        "Avoid rushing back to strenuous physical activities.",
        "Follow a phased return to work or daily tasks as advised by the GP.",
      ],
    },
    painManagement: {
      instructions: [
        "Use prescribed medications responsibly.",
        "Consider non-pharmacological options such as physiotherapy, acupuncture, or heat therapy.",
      ],
    },
  },
  duration: {
    weeks: [
      "1 week",
      "2 weeks",
      "3 weeks",
      "4 weeks (1 month)",
      "5 weeks",
      "6 weeks",
      "7 weeks",
      "8 weeks (2 months)",
      "9 weeks",
      "10 weeks",
      "11 weeks",
      "12 weeks (3 months)",
    ],
    months: [
      "1 month (4 weeks)",
      "2 months (8 weeks)",
      "3 months (12 weeks)",
      "4 months (16 weeks)",
      "5 months (20 weeks)",
      "6 months (24 weeks)",
      "7 months (28 weeks)",
      "8 months (32 weeks)",
      "9 months (36 weeks)",
    ],
  },
  injuryAndAccidentPurpose: {
    purpose: [
      "Insurance claim",
      "Legal proceedings",
      "Time off work",
      "Workplace compensation claim",
      "Personal injury claim",
      "Travel insurance claim",
      "Health insurance reimbursement",
      "Car accident compensation",
      "Public liability claim",
      "Court evidence for assault or harm",
      "Support for disability benefits application",
      "Validation for rehabilitation services",
      "School or university absence verification",
    ],
  },
};

export default injuryAndAccidentData;
