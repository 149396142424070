/** @format */

const selfCertificationConditions = {
  content: {
    data: `This is to certify that [Patient’s Full Name] has informed me that they have been unwell and unable to attend work or usual activities due to [Self Certification Conditions] from [start date] to [end date]. For periods of illness lasting seven days or fewer, a self-certification form is sufficient under UK guidelines. Should their symptoms persist or worsen, they have been advised to seek further medical attention.`,
  },
  conditions: {
    commonColdOrFlu: ["Fever", "Cough", "Fatigue"],
    gastrointestinalUpset: [
      "Food poisoning",
      "Stomach viruses causing nausea",
      "Vomiting",
      "Diarrhea",
    ],
    minorInjuries: ["Sprains", "Strains"],
    migraineOrTensionHeadaches: [
      "Severe headaches",
      "Rest and recovery needed",
    ],
    mildRespiratoryInfections: ["Sinusitis", "Bronchitis"],
    shortTermMentalHealthIssues: ["Acute stress", "Anxiety episodes"],
  },
};

export default selfCertificationConditions;
