/** @format */

const vaccineExemptionVaccineData = {
  content: {
    data: `This is to certify that [Patient's Full Name] has been assessed and has a medical condition that exempts them from receiving the [Vaccine Exemption Vaccine]. 
The exemption is based on the following medical contraindication(s): 
    [Vaccine Exemption Condition 1, e.g., a history of severe allergic reactions (anaphylaxis) to vaccine components] 
    [Vaccine Exemption Condition 2, if applicable]. 
    This exemption is issued in accordance with current UK health guidelines and should be recognised for any situation requiring vaccination proof.`,
  },
  vaccineTypes: {
    routineChildhoodVaccinations: {
      description: "UK Immunisation Schedule",
      vaccines: [
        "Diphtheria, Tetanus, and Pertussis (DTaP)",
        "Polio (IPV)",
        "Haemophilus Influenzae Type B (Hib)",
        "Hepatitis B (HepB) (as part of the 6-in-1 vaccine)",
        "Measles, Mumps, and Rubella (MMR)",
        "Rotavirus",
        "Meningococcal B (MenB)",
        "Meningococcal ACWY (MenACWY)",
        "Pneumococcal Conjugate Vaccine (PCV)",
      ],
    },
    travelVaccinations: {
      description: "Required or recommended for international travel",
      vaccines: [
        "Yellow Fever",
        "Typhoid",
        "Hepatitis A and B (combined or separate)",
        "Rabies",
        "Japanese Encephalitis",
        "Cholera",
        "Tick-Borne Encephalitis",
      ],
    },
    covidVaccinations: {
      description: "COVID-19 approved vaccines",
      vaccines: [
        "Pfizer-BioNTech (Comirnaty)",
        "Moderna (Spikevax)",
        "AstraZeneca (Vaxzevria)",
        "Novavax (Nuvaxovid)",
      ],
    },
    seasonalVaccinations: {
      description: "Regular seasonal vaccinations",
      vaccines: ["Influenza (Flu Vaccine) (annual)"],
    },
    riskGroupVaccinations: {
      description: "Vaccines for specific risk groups",
      vaccines: [
        "Human Papillomavirus (HPV)",
        "Shingles (Herpes Zoster Vaccine)",
        "Tuberculosis (BCG Vaccine)",
        "Pneumococcal Polysaccharide Vaccine (PPV23)",
      ],
    },
    occupationalVaccinations: {
      description: "Required for specific occupations",
      vaccines: ["Hepatitis B", "Tetanus boosters", "Anthrax"],
    },
    specialCircumstanceVaccinations: {
      description: "For specific situations or outbreaks",
      vaccines: ["Smallpox Vaccine", "Monkeypox Vaccine", "Ebola Vaccine"],
    },
  },
  vaccineExemption: {
    conditions: [
      "Severe Allergic Reactions",

      "Severe Immuno Compromised States",
      " Acute Major Illness",
      " Pregnancy",
      "Cardiac History",
      "Other Contraindications",
    ],
  },
};

export default vaccineExemptionVaccineData;
