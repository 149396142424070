/** @format */

const blueBadgeData = {
  content: {
    data: `I am writing to support [Patient’s Full Name]’s application for a Blue Badge. [Patient’s Full Name] has been diagnosed with [Blue Badge Condition], which significantly affects their mobility and ability to undertake daily activities.
Due to their condition, [Patient’s Full Name] experiences: 
    [Blue Badge Symptom 1, e.g., chronic pain in the lower limbs] \
    [Blue Badge Symptom 2, e.g., severe breathlessness after short distances] 
    [Blue Badge Symptom 3, if applicable, e.g., reliance on walking aids]. 
    They are unable to walk more than 50 metres without severe discomfort, fatigue, or risk of exacerbation of their symptoms. A Blue Badge would provide them with essential access to facilities and improve their quality of life. I strongly recommend that their application is considered favourably, as their medical condition meets the criteria for eligibility.`,
  },
  blueBadgeConditions: {
    conditions: [
      "Higher rate of the mobility component of Disability Living Allowance (DLA)",
      "Personal Independence Payment (PIP) with a score of 8 points or more under the 'moving around' activity",
      "PIP with a score of 10 points under the 'planning and following journeys' activity, specifically descriptor E, indicating an inability to undertake any journey due to overwhelming psychological distress",
      "War Pensioners’ Mobility Supplement",
      "A lump sum benefit from the Armed Forces and Reserve Forces Compensation Scheme (within tariff levels 1 to 8) and certified as having a permanent and substantial disability causing inability to walk or considerable difficulty walking",
      "Registered blind (severely sight impaired)",
      "Inability to walk",
      "Considerable difficulty walking without assistance or mobility aids",
      "Severe discomfort or risk to health and safety when walking",
      "Severe disability in both arms, making it difficult to operate parking meters, and you drive regularly",
      "Severe psychological distress or anxiety when planning or following journeys",
      "Significant risk to yourself or others near vehicles, in traffic, or car parks",
      "Arthritis",
      "Muscular dystrophy",
      "Cerebral palsy",
      "Amputation or limb deformities",
      "Chronic back conditions",
      "Multiple sclerosis",
      "Parkinson’s disease",
      "Epilepsy (with mobility implications)",
      "Stroke resulting in mobility issues",
      "Chronic obstructive pulmonary disease (COPD)",
      "Cystic fibrosis",
      "Severe asthma",
      "Heart failure",
      "Autism spectrum disorders",
      "Dementia",
      "Schizophrenia",
      "Severe anxiety disorders",
      "Inflammatory bowel disease (e.g., Crohn’s disease) with severe symptoms",
      "Cancer affecting mobility",
      "Severe chronic pain conditions (e.g., fibromyalgia)",
    ],
  },

  symptoms: {
    MobilityRelatedSymptoms: [
      "Severe pain when walking (e.g., from arthritis, musculoskeletal disorders)",
      "Inability to walk more than short distances without stopping",
      "Difficulty standing for prolonged periods",
      "Use of mobility aids such as wheelchairs, walking frames, or sticks",
      "Loss of balance or coordination (e.g., from neurological conditions)",
      "Partial or total paralysis",
      "Amputation or severe deformity of limbs",
      "Fatigue or breathlessness after minimal exertion (e.g., COPD, heart failure)",
    ],

    NeurologicalSymptoms: [
      "Muscle weakness or spasticity (e.g., multiple sclerosis, stroke aftereffects)",
      "Tremors or uncontrolled movements (e.g., Parkinson’s disease)",
      "Seizures or risk of falling due to epilepsy or other conditions",
      "Cognitive impairment leading to disorientation or risk of harm when traveling alone (e.g., dementia, brain injuries)",
    ],

    RespiratoryAndCardiacSymptoms: [
      "Persistent breathlessness, even at rest (e.g., severe asthma, COPD)",
      "Chest pain or discomfort with exertion (e.g., heart failure, angina)",
      "Fatigue or inability to engage in daily activities due to poor oxygenation",
    ],

    PsychologicalAndCognitiveSymptoms: [
      "Severe anxiety or panic attacks when traveling",
      "Overwhelming psychological distress when planning or undertaking journeys",
      "Difficulty with spatial awareness or sensory processing (e.g., autism spectrum disorders)",
      "Cognitive difficulties resulting in inability to follow directions or navigate unfamiliar environments",
      "Risk of harm to self or others near traffic or in car parks (e.g., due to confusion or unpredictable behavior)",
    ],

    SymptomsFromSpecificConditions: {
      "Arthritis And Joint Disorders": [
        "Stiffness, swelling, and reduced range of motion",
        "Pain exacerbated by walking or standing",
      ],
      "Cerebral Palsy": [
        "Muscle tightness, lack of coordination, or spastic movements",
      ],
      Cancer: ["Fatigue, weakness, or pain limiting mobility"],
      "Amputations Or Limb Deformities": [
        "Loss of limb function, balance issues",
      ],
      "Inflammatory Bowel Disease": [
        "Urgent need to find restroom facilities, which may limit travel distance",
      ],
    },

    VisionRelatedSymptoms: [
      "Total or partial blindness causing difficulty in navigating safely",
      "Risk of falls or injuries due to poor vision",
    ],

    SymptomsFromRareConditions: [
      "Chronic Pain Disorders (e.g., fibromyalgia): Persistent, widespread pain that worsens with movement",
      "Neurological Disorders (e.g., motor neurone disease): Progressive muscle weakness and mobility loss",
      "Autism or Sensory Disorders: Sensory overload, risk of meltdowns in busy environments, or difficulty with social interactions impacting travel safety",
    ],

    GeneralIndicatorsOfSevereDifficulty: [
      "Reliance on carers or support persons to navigate journeys",
      "Frequent use of assistive devices for mobility or safety",
      "Risk of collapse, fainting, or falls in public places",
      "Frequent pauses to recover when walking short distances",
      "Complete inability to walk without pain, risk, or assistance",
    ],
  },
};

export default blueBadgeData;
