/** @format */

const covidTest = {
  content: {
    data: `This letter is to verify that [Patient’s Full Name] underwent a Covid-19 test on [date]. The test result was [Covid-19 Test Result] and was confirmed on [date]. The test conducted was a [Covid-19 Test Type], which adheres to current NHS and public health guidelines. `,
  },
  results: {
    testResult: ["positive", "negative"],
  },
  types: {
    testType: ["PCR", "Lateral Flow Test"],
  },
};

export default covidTest;
