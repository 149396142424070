/** @format */

const gpReferralConditions = {
  content: {
    data: `I am writing to refer [Patient’s Full Name] for further assessment and/or management at your facility. [Patient’s Full Name] has been under my care during which they have presented with [GP Referral Conditions]. I would appreciate your expertise in evaluating and managing [GP Referral Conditions]. `,
  },
  conditions: {
    cardiovascularIssues: ["hypertension", "suspectedHeartDisease"],
    respiratoryConditions: ["copd", "asthma"],
    gastrointestinalDisorders: ["ibs", "suspectedIbd"],
    neurologicalConcerns: ["chronicHeadachesOrMigraines", "seizures"],
    musculoskeletalProblems: [
      "severeOsteoarthritis",
      "suspectedRheumatoidArthritis",
    ],
    mentalHealthConditions: ["depressionOrAnxiety", "suspectedPsychosis"],
    dermatologicalIssues: ["suspiciousSkinLesions", "severeEczemaOrPsoriasis"],
    endocrineDisorders: ["thyroidDysfunction", "diabetesMellitus"],
    urologicalConcerns: ["prostateIssues", "recurrentUrinaryTractInfections"],
    gynecologicalConditions: [
      "abnormalUterineBleeding",
      "suspectedEndometriosis",
    ],
    oncologicalSuspicions: [
      "unexplainedWeightLoss",
      "persistentLymphadenopathy",
    ],
    ophthalmologicalIssues: ["suddenVisionLoss", "diabeticRetinopathy"],
    pediatricConcerns: ["developmentalDelays", "congenitalAnomalies"],
    surgicalConditions: ["hernias", "gallstones"],
    renalIssues: ["chronicKidneyDisease", "recurrentKidneyStones"],
  },
};

export default gpReferralConditions;
