<!-- @format -->

<template>
  <div class="bg-white px-6 py-4 rounded-md shadow-lg w-1/3">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-lg font-medium">Reason For Rejection</h2>
      <button
        class="material-icons bg-red-500 text-sm rounded-full text-white w-5 font-bold"
        @click="closeModal"
      >
        close
      </button>
    </div>
    <input
      type="text"
      v-model="reason"
      class="w-full p-2 border border-gray-300 rounded-md"
      rows="1"
      placeholder="Type your reason for rejection..."
      maxlength="512"
    />
    <div class="flex justify-end mt-4">
      <button
        class="bg-teal text-white px-4 py-2 rounded-md"
        :class="{ 'bg-opacity-60 cursor-not-allowed': !reason }"
        :disabled="!reason"
        @click="handleSubmit"
      >
        Reject & Refund
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const emits = defineEmits(["close-modal", "submit-modal"]);

const reason = ref("");

const closeModal = () => {
  emits("close-modal");
  reason.value = "";
};

const handleSubmit = () => {
  emits("submit-modal", reason.value);
  closeModal();
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
