/** @format */

const eventAndActivityCancellationReasons = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] is unable to attend or participate in [Event or Activity Free Text] scheduled for [date(s)] due to medical reasons. They have been under my care and have been advised to refrain from attending due to [Event And Activity Cancellation Reason]. This letter is provided to support their request for cancellation or rescheduling of their attendance.`,
  },
  reason: {
    acuteIllnesses: ["Influenza", "Gastroenteritis", "Severe infections"],
    injuries: ["Fractures", "Sprains", "Other mobility-impeding injuries"],
    chronicConditions: [
      "Severe asthma attacks",
      "Uncontrolled diabetes",
      "Cardiac events",
    ],
    mentalHealthEpisodes: ["Severe anxiety", "Depression", "Panic attacks"],
    surgicalProcedures: ["Recovery from recent surgeries"],
    contagiousDiseases: ["Chickenpox", "Measles"],
  },
};

export default eventAndActivityCancellationReasons;
