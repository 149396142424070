<!-- @format -->

<template>
  <div>
    <TableSkeletonLoader
      v-if="props.loader"
      :loader="props.loader"
      :cols="5"
      :rows="4"
    />
    <div v-else>
      <div
        v-if="tableBody?.length === 0"
        class="flex justify-center items-center"
      >
        <div class="flex-col justify-center items-center">
          <div class="rounded-full p-12 icon-bg">
            <img src="../../../assets/icons/noData.svg" class="w-44" />
          </div>
          <div class="mt-4 text-teal font-medium flex items-center">
            <i class="material-icons text-teal w-8">info_outlined</i>
            No Requests Available Currently!
          </div>
        </div>
      </div>
      <table v-else class="w-full">
        <thead class="bg-teal text-left">
          <th
            v-for="(heading, id) in tableHeading"
            :key="id"
            class="px-6 py-4 text-white font-thin text-sm"
          >
            {{ heading }}
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(data, id) in tableBody.data"
            :key="id"
            class="border bottom-1 text-left"
          >
            <td
              class="px-6 py-5 font-thin text-sm max-clamp truncate capitalize"
            >
              {{ data?.first_name + " " + data?.last_name }}
            </td>
            <td
              class="px-6 py-5 font-thin text-sm max-clamp truncate capitalize"
            >
              {{ data?.gmc_number }}
            </td>
            <td class="px-6 py-5 font-thin text-sm max-clamp truncate">
              {{ moment(data?.created_at).format("MMM DD, YYYY") }}
            </td>
            <td class="px-6 py-5 font-thin text-sm">
              <span
                class="px-4 rounded-lg py-1"
                :class="
                  data?.gmc_status.includes('Pending Verification')
                    ? 'bg-blue bg-opacity-10 text-blue'
                    : data?.gmc_status.includes('Active')
                    ? 'bg-teal bg-opacity-10 text-teal'
                    : ' bg-orange bg-opacity-10 text-orange'
                "
              >
                {{ data?.gmc_status }}
              </span>
            </td>

            <td class="w-28">
              <span
                class="border-teal border px-2 py-1 font-thin text-sm rounded-lg text-teal cursor-pointer hover:bg-teal hover:text-white material-icons"
                @click="navigate(data?.id)"
              >
                edit
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { defineProps, toRefs } from "vue";
import { useRouter } from "vue-router";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import moment from "moment";

const props = defineProps({
  tableHeading: {
    type: Array,
    required: true,
  },
  tableBody: {
    type: Array,
    required: true,
  },
  loader: {
    type: Boolean,
  },
});

const { tableBody, tableHeading } = toRefs(props);

const router = useRouter();

const navigate = (doctorId) => {
  router.push({
    name: "viewDoctor",
    params: { doctorId: doctorId, isView: 1 },
  });
};
</script>
