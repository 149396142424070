<!-- @format -->

<template>
  <div class="">
    <div class="tabs" :class="props.isDash ? 'flex ' : ''">
      <template v-if="isLoading">
        <div class="flex">
          <div
            v-for="index in tabs?.length"
            :key="index"
            class="h-6 w-24 bg-gray-300 rounded-md mr-6 animate-pulse"
          ></div>
        </div>
      </template>
      <template v-else>
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          class="py-2 mr-6"
          :class="
            props.isDash
              ? activeTab === index
                ? 'text-teal border-b-2 border-teal w-full'
                : 'text-textGray w-full'
              : activeTab === index
              ? 'text-teal border-b-2 border-teal '
              : 'text-textGray '
          "
          @click="changeTab(index, tab)"
        >
          {{ tab.name }}
        </button>
        <hr v-if="bottomBorder" class="border-t-2" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, onMounted, defineEmits } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const emit = defineEmits();

const tabs = ref([]);

const props = defineProps({
  tabsData: Array,
  activeTabIndex: Number,
  bottomBorder: {
    type: Boolean,
    default: true,
  },
  isDash: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const { tabsData } = toRefs(props);
const activeTab = ref(props.activeTabIndex || 0);

const changeTab = (index, tab) => {
  router.push({ name: tab.content });
  activeTab.value = index;
  emit("index", activeTab.value);
};

onMounted(() => {
  tabs.value = tabsData.value.map((tab, index) => ({
    name: tab.name,
    content: tab.content,
    index,
  }));
});
</script>
