/** @format */

import allergyData from "@/dataObject/medicalCertificates/allergyCertificate.js";
import blueBadgeData from "@/dataObject/medicalCertificates/blueBadgeCertificate.js";
import workSicknessConditionsData from "@/dataObject/medicalCertificates/workSicknessCertificate.js";
import universityCollegeSicknessConditionsData from "@/dataObject/medicalCertificates/universityAndCollegeSickness.js";
import travelAndHolidayCancellationConditionsData from "@/dataObject/medicalCertificates/travelAndHolidayCancellation.js";
import selfCertificationConditionsData from "@/dataObject/medicalCertificates/selfCertification.js";
import pregnancyFitToFlyData from "@/dataObject/medicalCertificates/pregnancyFitToFly.js";
import gpReferralConditionsData from "@/dataObject/medicalCertificates/gpReferral.js";
import vaccineExemptionVaccineData from "../dataObject/medicalCertificates/vaccineExemptionCertificate";
import travelWithMedication from "../dataObject/medicalCertificates/travelWithMedication";
import disabilityMedicalData from "@/dataObject/medicalCertificates/disabilityMedicalCertificate.js";
import eventAndActivityCancellationReasonsData from "@/dataObject/medicalCertificates/eventAndActivityCancellation.js";
import gymHealthClubCancellationConditionsData from "@/dataObject/medicalCertificates/gymAndHealthClubCancellation.js";
import goodHealthCertificateData from "@/dataObject/medicalCertificates/goodHealthCertificate.js";
import covidTestData from "@/dataObject/medicalCertificates/covid_19TestVerification.js";
import housingSupportData from "../dataObject/medicalCertificates/housingSupport";
import injuryAndAccidentData from "../dataObject/medicalCertificates/injuryAndAccident";
import medicalExemptionConditionsData from "../dataObject/medicalCertificates/medicalExemptionCertificate";

export const useMedicalData = () => {
  const substringsToCheck = [
    "@[Allergy Type]",
    "@[Allergy Testing Method]",
    "@[Allergy Symptom]",
    "@[specific allergens]",
    // "@[Specific Medication]",
    "@[Blue Badge Symptom]",
    "@[Blue Badge Condition]",
    "@[Work Sickness Condition]",
    "@[Vaccine Exemption Vaccine]",
    "@[Vaccine Exemption Condition]",
    "@[University & College Sickness Condition]",
    "@[Travel with Medication Condition]",
    "@[Travel with Medication Items]",
    "@[Travel & Holiday Cancellation Conditions]",
    "@[Self Certification Conditions]",
    "@[Pregnancy Fit To Fly Gestational]",
    "@[GP Referral Conditions]",
    "@[Gym & Health Club Cancellation Conditions]",
    "@[Good Health Tasks]",
    "@[Covid-19 Test Result]",
    "@[Covid-19 Test Type]",
    "@[Event And Activity Cancellation Reason]",
    "@[Disability Medical Conditions]",
    "@[Disability Medical Impacts]",
    "@[Disability Medical Accommodations]",
    "@[Disability Medical Purpose]",
    "@[Housing Support Conditions]",
    "@[Housing Support Requirement]",
    "@[Injury And Accident Injury]",
    "@[Injury And Accident Instructions]",
    "@[Injury And Accident Purpose]",
    "@[Injury And Accident Duration]",
    "@[General Recovery Recommendations]",
    "@[Medical Exemption Conditions]",
    "@[Medical Exemption Reasons]",
  ];

  const getRelevantData = (content) => {
    const foundSubstring = substringsToCheck.find((substring) =>
      content.includes(substring)
    );

    if (foundSubstring) {
      const substringLength = foundSubstring.length;

      return {
        data: getStringData(foundSubstring),
        substring: foundSubstring,
        length: substringLength,
      };
    }
    return null;
  };

  const getStringData = (string) => {
    switch (string) {
      case "@[Allergy Type]":
        return {
          "Types of Allergies": allergyData.AllergyTypes,
        };

      case "@[Allergy Testing Method]":
        return {
          "Testing Methods": allergyData.AllergyTestingMethods,
        };

      case "@[Allergy Symptom]":
        return {
          "Food Allergy Symptoms": allergyData.AllergySymptoms.FoodAllergies,
          "Inhalant Allergy Symptoms":
            allergyData.AllergySymptoms.InhalantAllergies,
          "Insect Allergy Symptoms":
            allergyData.AllergySymptoms.InsectAllergies,
          "Contact Allergy Symptoms":
            allergyData.AllergySymptoms.ContactAllergies,
          "Medication Allergy Symptoms":
            allergyData.AllergySymptoms.MedicationAllergies,
          "Environmental Allergy Symptoms":
            allergyData.AllergySymptoms.EnvironmentalAllergies,
          "Drug Induced Allergy Symptoms":
            allergyData.AllergySymptoms.DrugInducedAllergies,
          "Skin Allergy Symptoms": allergyData.AllergySymptoms.SkinAllergies,
          "Occupational Allergy Symptoms":
            allergyData.AllergySymptoms.OccupationalAllergies,
          "Other Rare Allergy Symptoms":
            allergyData.AllergySymptoms.RareAllergies,
          "General Anaphylaxis Symptoms":
            allergyData.AllergySymptoms.GeneralAnaphylaxisSymptoms,
        };

      case "@[specific allergens]":
        return {
          "Food Allergens": allergyData.Allergens.FoodAllergies,
          "Inhalant Allergens": allergyData.Allergens.InhalantAllergies,
          "Insect Allergens": allergyData.Allergens.InsectAllergies,
        };

      // case "@[Specific Medication]":
      //   return {
      //     "Allergy Medications": allergyData.Medications,
      //   };

      case "@[Blue Badge Symptom]":
        return {
          "Mobility Related Symptoms":
            blueBadgeData.symptoms.MobilityRelatedSymptoms,
          "Respiratory and Cardiac Symptoms":
            blueBadgeData.symptoms.RespiratoryAndCardiacSymptoms,
          "Psychological and Cognitive Symptoms":
            blueBadgeData.symptoms.PsychologicalAndCognitiveSymptoms,
          "Condition Specific Symptoms":
            blueBadgeData.symptoms.SymptomsFromSpecificConditions,
          "Vision Related Symptoms":
            blueBadgeData.symptoms.VisionRelatedSymptoms,
          "Rare Condition Symptoms":
            blueBadgeData.symptoms.SymptomsFromRareConditions,
          "General Difficulty Indicators":
            blueBadgeData.symptoms.GeneralIndicatorsOfSevereDifficulty,
        };

      case "@[Blue Badge Condition]":
        return {
          "Qualifying Medical Conditions":
            blueBadgeData.blueBadgeConditions.conditions,
        };

      case "@[Work Sickness Condition]":
        return {
          "Work Sickness Conditions":
            workSicknessConditionsData?.workSicknessConditions.condition,
        };

      case "@[Vaccine Exemption Vaccine]":
        return {
          "Routine Childhood Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes
              .routineChildhoodVaccinations.vaccines,
          "Travel Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes.travelVaccinations
              .vaccines,
          "COVID-19 Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes.covidVaccinations.vaccines,
          "Seasonal Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes.seasonalVaccinations
              .vaccines,
          "Risk Group Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes.riskGroupVaccinations
              .vaccines,
          "Occupational Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes.occupationalVaccinations
              .vaccines,
          "Special Circumstance Vaccinations":
            vaccineExemptionVaccineData.vaccineTypes
              .specialCircumstanceVaccinations.vaccines,
        };

      case "@[Vaccine Exemption Condition]":
        return {
          "Vaccine Exemption Conditions":
            vaccineExemptionVaccineData.vaccineExemption.conditions,
        };

      case "@[University & College Sickness Condition]":
        return {
          "Academic Medical Conditions":
            universityCollegeSicknessConditionsData?.universityCollegeSickness
              .Conditions,
        };

      case "@[Travel with Medication Condition]":
        return {
          "Medical Conditions": travelWithMedication.conditions,
        };

      case "@[Travel with Medication Items]":
        return {
          "Prescribed Medications": {
            Medications: travelWithMedication.items.medications,
            "Medical Devices and Equipment":
              travelWithMedication.items.medicalDevicesAndEquipment,
            "Administration Items":
              travelWithMedication.items.administrationItems,
            "Storage and Care": travelWithMedication.items.storageAndCare,
            Documentation: travelWithMedication.items.documentation,
          },
        };

      case "@[Travel & Holiday Cancellation Conditions]":
        return {
          "Medical Conditions":
            travelAndHolidayCancellationConditionsData
              ?.travelAndHolidayCancellation.Conditions,
        };

      case "@[Self Certification Conditions]":
        return {
          "Common Cold or Flu":
            selfCertificationConditionsData.conditions.commonColdOrFlu,
          "Gastrointestinal Issues":
            selfCertificationConditionsData.conditions.gastrointestinalUpset,
          "Minor Injuries":
            selfCertificationConditionsData.conditions.minorInjuries,
          "Headaches and Migraines":
            selfCertificationConditionsData.conditions
              .migraineOrTensionHeadaches,
          "Respiratory Infections":
            selfCertificationConditionsData.conditions
              .mildRespiratoryInfections,
          "Mental Health Issues":
            selfCertificationConditionsData.conditions
              .shortTermMentalHealthIssues,
        };

      case "@[Pregnancy Fit To Fly Gestational]":
        return {
          "Pregnancy Duration": {
            Weeks: pregnancyFitToFlyData?.duration.weeks,
            Months: pregnancyFitToFlyData?.duration.months,
          },
        };

      case "@[GP Referral Conditions]":
        return {
          "Cardiovascular Issues":
            gpReferralConditionsData.conditions.cardiovascularIssues,
          "Respiratory Conditions":
            gpReferralConditionsData.conditions.respiratoryConditions,
          "Gastrointestinal Disorders":
            gpReferralConditionsData.conditions.gastrointestinalDisorders,
          "Neurological Concerns":
            gpReferralConditionsData.conditions.neurologicalConcerns,
          "Musculoskeletal Problems":
            gpReferralConditionsData.conditions.musculoskeletalProblems,
          "Mental Health Conditions":
            gpReferralConditionsData.conditions.mentalHealthConditions,
          "Dermatological Issues":
            gpReferralConditionsData.conditions.dermatologicalIssues,
          "Endocrine Disorders":
            gpReferralConditionsData.conditions.endocrineDisorders,
          "Urological Concerns":
            gpReferralConditionsData.conditions.urologicalConcerns,
          "Gynecological Conditions":
            gpReferralConditionsData.conditions.gynecologicalConditions,
          "Oncological Suspicions":
            gpReferralConditionsData.conditions.oncologicalSuspicions,
          "Ophthalmological Issues":
            gpReferralConditionsData.conditions.ophthalmologicalIssues,
          "Pediatric Concerns":
            gpReferralConditionsData.conditions.pediatricConcerns,
          "Surgical Conditions":
            gpReferralConditionsData.conditions.surgicalConditions,
          "Renal Issues": gpReferralConditionsData.conditions.renalIssues,
        };

      case "@[Gym & Health Club Cancellation Conditions]":
        return {
          "Musculoskeletal Conditions":
            gymHealthClubCancellationConditionsData.conditions
              .musculoskeletalInjuries,
          "Cardiovascular Conditions":
            gymHealthClubCancellationConditionsData.conditions
              .cardiovascularConditions,
          "Respiratory Disorders":
            gymHealthClubCancellationConditionsData.conditions
              .respiratoryDisorders,
          "Neurological Disorders":
            gymHealthClubCancellationConditionsData.conditions
              .neurologicalDisorders,
          "Mental Health Issues":
            gymHealthClubCancellationConditionsData.conditions
              .mentalHealthIssues,
          "Post-Surgical Recovery":
            gymHealthClubCancellationConditionsData.conditions
              .postSurgicalRecovery,
          "Chronic Illnesses":
            gymHealthClubCancellationConditionsData.conditions.chronicIllnesses,
        };

      case "@[Good Health Tasks]":
        return {
          "Purpose of Certification": goodHealthCertificateData.goodHealthTasks,
        };

      case "@[Covid-19 Test Result]":
        return {
          "Test Results": covidTestData.results.testResult,
        };

      case "@[Covid-19 Test Type]":
        return {
          "Test Types": covidTestData.types.testType,
        };

      case "@[Event And Activity Cancellation Reason]":
        return {
          "Acute Illnesses":
            eventAndActivityCancellationReasonsData?.reason.acuteIllnesses,
          Injuries: eventAndActivityCancellationReasonsData?.reason.injuries,
          "Chronic Conditions":
            eventAndActivityCancellationReasonsData?.reason.chronicConditions,
          "Mental Health Episodes":
            eventAndActivityCancellationReasonsData?.reason
              .mentalHealthEpisodes,
          "Surgical Procedures":
            eventAndActivityCancellationReasonsData?.reason.surgicalProcedures,
          "Contagious Diseases":
            eventAndActivityCancellationReasonsData?.reason.contagiousDiseases,
        };

      case "@[Disability Medical Conditions]":
        return {
          "Musculoskeletal Disorders": {
            "Arthritis Conditions":
              disabilityMedicalData.disabilityMedicalDisorders
                .musculoskeletalDisorders.conditions,
            "Back Pain Conditions":
              disabilityMedicalData.disabilityMedicalDisorders
                .musculoskeletalDisorders.conditions,
            "Spondylosis Conditions":
              disabilityMedicalData.disabilityMedicalDisorders
                .musculoskeletalDisorders.conditions,
          },
          "Neurological Conditions": {
            "Multiple Sclerosis":
              disabilityMedicalData.disabilityMedicalDisorders
                .neurologicalConditions.conditions,
            "Parkinson's Disease":
              disabilityMedicalData.disabilityMedicalDisorders
                .neurologicalConditions.conditions,
            Epilepsy:
              disabilityMedicalData.disabilityMedicalDisorders
                .neurologicalConditions.conditions,
            "Motor Neurone Disease":
              disabilityMedicalData.disabilityMedicalDisorders
                .neurologicalConditions.conditions,
          },
          "Cardiovascular and Respiratory": {
            "Heart Disease":
              disabilityMedicalData.disabilityMedicalDisorders
                .cardiovascularAndRespiratoryDisorders.conditions,
            COPD: disabilityMedicalData.disabilityMedicalDisorders
              .cardiovascularAndRespiratoryDisorders.conditions,
            Asthma:
              disabilityMedicalData.disabilityMedicalDisorders
                .cardiovascularAndRespiratoryDisorders.conditions,
          },
          "Cognitive and Developmental": {
            Dementia:
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.conditions,
            "Autism Spectrum Disorders":
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.conditions,
            "Learning Disabilities":
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.conditions,
          },
          "Sensory Impairments": {
            "Visual Impairments":
              disabilityMedicalData.disabilityMedicalDisorders
                .sensoryImpairments.conditions,
            "Hearing Impairments":
              disabilityMedicalData.disabilityMedicalDisorders
                .sensoryImpairments.conditions,
          },
          "Chronic Pain and Fatigue": {
            Fibromyalgia:
              disabilityMedicalData.disabilityMedicalDisorders
                .chronicPainAndFatigueSyndromes.conditions,
            "Chronic Fatigue Syndrome":
              disabilityMedicalData.disabilityMedicalDisorders
                .chronicPainAndFatigueSyndromes.conditions,
          },
          "Autoimmune and Systemic": {
            Lupus:
              disabilityMedicalData.disabilityMedicalDisorders
                .autoimmuneAndSystemicConditions.conditions,
            "Crohn's Disease":
              disabilityMedicalData.disabilityMedicalDisorders
                .autoimmuneAndSystemicConditions.conditions,
          },
          Cancer: {
            Description:
              disabilityMedicalData.disabilityMedicalDisorders.cancer
                .conditions,
          },
          "Terminal Illnesses": {
            Description:
              disabilityMedicalData.disabilityMedicalDisorders.terminalIllnesses
                .conditions,
          },
        };

      case "@[Disability Medical Impacts]":
        return {
          "Musculoskeletal Impacts": {
            "Arthritis Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .musculoskeletalDisorders.impacts.arthritis,
            "Back Pain Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .musculoskeletalDisorders.impacts.backPain,
          },
          "Cardiovascular and Respiratory Impacts": {
            "Heart Disease Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cardiovascularAndRespiratoryDisorders.impacts.heartDisease,
            "COPD Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cardiovascularAndRespiratoryDisorders.impacts
                .chronicObstructivePulmonaryDisease,
            "Asthma Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cardiovascularAndRespiratoryDisorders.impacts.asthma,
          },
          "Mental Health Impacts": {
            "Depression Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .mentalHealthConditions.impacts.depression,
            "Anxiety Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .mentalHealthConditions.impacts.anxietyDisorders,
            "Schizophrenia Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .mentalHealthConditions.impacts.schizophrenia,
            "Bipolar Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .mentalHealthConditions.impacts.bipolarDisorder,
          },
          "Cognitive and Developmental Impacts": {
            "Dementia Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.impacts.dementia,
            "Autism Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.impacts
                .autismSpectrumDisorders,
            "Learning Disability Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .cognitiveDevelopmentalDisorders.impacts.learningDisabilities,
          },
          "Sensory Impairment Impacts": {
            "Visual Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .sensoryImpairments.impacts.visualImpairments,
            "Hearing Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .sensoryImpairments.impacts.hearingImpairments,
          },
          "Chronic Pain and Fatigue Impacts": {
            "Fibromyalgia Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .chronicPainAndFatigueSyndromes.impacts.fibromyalgia,
            "CFS Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .chronicPainAndFatigueSyndromes.impacts.chronicFatigueSyndrome,
          },
          "Autoimmune and Systemic Impacts": {
            "Lupus Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .autoimmuneAndSystemicConditions.impacts.lupus,
            "Crohn's Related":
              disabilityMedicalData.disabilityMedicalDisorders
                .autoimmuneAndSystemicConditions.impacts.crohnsDisease,
          },
        };

      case "@[Disability Medical Accommodations]":
        return {
          "Mobility Related": {
            "Mobility Aids":
              disabilityMedicalData.disabilityMedicalAccomodations
                .mobilityRelatedAccommodations.mobilityAids,
            "Home Modifications":
              disabilityMedicalData.disabilityMedicalAccomodations
                .mobilityRelatedAccommodations.homeModifications,
            "Transportation Support":
              disabilityMedicalData.disabilityMedicalAccomodations
                .mobilityRelatedAccommodations.transportationSupport,
            "Support for Travel":
              disabilityMedicalData.disabilityMedicalAccomodations
                .mobilityRelatedAccommodations.supportForTravel,
          },
          Workplace: {
            "Flexible Working Hours":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.flexibleWorkingHours,
            "Ergonomic Equipment":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.ergonomicOfficeEquipment,
            "Adjusted Tasks":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.adjustedTasks,
            "Regular Breaks":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.regularBreaks,
            "Accessible Workspaces":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.accessibleWorkspaces,
            "Mental Health Support":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.mentalHealthSupport,
            "Assistive Technology":
              disabilityMedicalData.disabilityMedicalAccomodations
                .workplaceAccommodations.assistiveTechnology,
          },
          "Housing Support": {
            "Accessible Housing":
              disabilityMedicalData.disabilityMedicalAccomodations
                .housingSupport.accessibleHousing,
            "Financial Assistance":
              disabilityMedicalData.disabilityMedicalAccomodations
                .housingSupport.financialAssistance,
            "Support Services":
              disabilityMedicalData.disabilityMedicalAccomodations
                .housingSupport.supportServices,
            "Specialist Accommodation":
              disabilityMedicalData.disabilityMedicalAccomodations
                .housingSupport.specialistAccommodation,
          },
          "Assistive Technology": {
            "Visual Impairments":
              disabilityMedicalData.disabilityMedicalAccomodations
                .assistiveTechnology.visualImpairments,
            "Hearing Impairments":
              disabilityMedicalData.disabilityMedicalAccomodations
                .assistiveTechnology.hearingImpairments,
            "Physical Disabilities":
              disabilityMedicalData.disabilityMedicalAccomodations
                .assistiveTechnology.physicalDisabilities,
            "Cognitive Disabilities":
              disabilityMedicalData.disabilityMedicalAccomodations
                .assistiveTechnology.cognitiveDisabilities,
          },
          "Medical and Therapeutic": {
            Physiotherapy:
              disabilityMedicalData.disabilityMedicalAccomodations
                .medicalAndTherapeuticSupport.regularPhysiotherapy,
            "Occupational Therapy":
              disabilityMedicalData.disabilityMedicalAccomodations
                .medicalAndTherapeuticSupport.occupationalTherapy,
            "Mental Health Support":
              disabilityMedicalData.disabilityMedicalAccomodations
                .medicalAndTherapeuticSupport.mentalHealthSupport,
            "Specialist Care":
              disabilityMedicalData.disabilityMedicalAccomodations
                .medicalAndTherapeuticSupport.specialistCare,
          },
          Educational: {
            "Education Plans":
              disabilityMedicalData.disabilityMedicalAccomodations
                .educationalSupport.individualizedEducationPlans,
            "Learning Support":
              disabilityMedicalData.disabilityMedicalAccomodations
                .educationalSupport.learningSupportAssistants,
            "Specialist Schools":
              disabilityMedicalData.disabilityMedicalAccomodations
                .educationalSupport.specialistSchools,
            "Exam Accommodations":
              disabilityMedicalData.disabilityMedicalAccomodations
                .educationalSupport.examAccommodations,
          },
          Financial: {
            "Disability Benefits":
              disabilityMedicalData.disabilityMedicalAccomodations
                .financialSupport.disabilityBenefits,
            "Carers Allowance":
              disabilityMedicalData.disabilityMedicalAccomodations
                .financialSupport.carersAllowance,
            "Facilities Grants":
              disabilityMedicalData.disabilityMedicalAccomodations
                .financialSupport.disabledFacilitiesGrants,
            "Free Prescriptions":
              disabilityMedicalData.disabilityMedicalAccomodations
                .financialSupport.freePrescriptions,
          },
          "Social and Community": {
            "Carers Support":
              disabilityMedicalData.disabilityMedicalAccomodations
                .socialAndCommunitySupport.carersOrPersonalAssistants,
            "Day Centers":
              disabilityMedicalData.disabilityMedicalAccomodations
                .socialAndCommunitySupport.dayCenters,
            "Advocacy Services":
              disabilityMedicalData.disabilityMedicalAccomodations
                .socialAndCommunitySupport.disabilityAdvocacyServices,
            "Respite Care":
              disabilityMedicalData.disabilityMedicalAccomodations
                .socialAndCommunitySupport.respiteCare,
          },
          "Condition Specific": {
            "Chronic Pain and Fatigue":
              disabilityMedicalData.disabilityMedicalAccomodations
                .accommodationsForSpecificConditions
                .chronicPainAndFatigueSyndromes,
            "Autism Spectrum":
              disabilityMedicalData.disabilityMedicalAccomodations
                .accommodationsForSpecificConditions.autismSpectrumDisorders,
            Dementia:
              disabilityMedicalData.disabilityMedicalAccomodations
                .accommodationsForSpecificConditions.dementia,
            Epilepsy:
              disabilityMedicalData.disabilityMedicalAccomodations
                .accommodationsForSpecificConditions.epilepsy,
          },
          Emergency: {
            "Help Lines":
              disabilityMedicalData.disabilityMedicalAccomodations
                .emergencySupport.emergencyHelplines,
            "Medical Alert":
              disabilityMedicalData.disabilityMedicalAccomodations
                .emergencySupport.medicalAlertBracelets,
            "Evacuation Plans":
              disabilityMedicalData.disabilityMedicalAccomodations
                .emergencySupport.emergencyEvacuationPlans,
          },
        };

      case "@[Disability Medical Purpose]":
        return {
          "Purpose of Medical Documentation":
            disabilityMedicalData.disabilityMedicalPurpose,
        };

      case "@[Housing Support Conditions]":
        return {
          "Respiratory Conditions":
            housingSupportData.conditions.respiratoryConditions,
          "Mobility Impairments":
            housingSupportData.conditions.mobilityImpairments,
          "Mental Health Disorders":
            housingSupportData.conditions.mentalHealthDisorders,
          "Neurological Conditions":
            housingSupportData.conditions.neurologicalConditions,
          "Sensory Impairments":
            housingSupportData.conditions.sensoryImpairments,
        };

      case "@[Housing Support Requirement]":
        return {
          "Accessibility Requirements":
            housingSupportData.requirements.accessibilityFeatures,
          "Environmental Conditions":
            housingSupportData.requirements.environmentalConditions,
          "Proximity to Services":
            housingSupportData.requirements.proximityToServices,
          "Space Requirements":
            housingSupportData.requirements.spaceRequirements,
          "Safety Considerations":
            housingSupportData.requirements.safetyConsiderations,
          "Specific Modifications":
            housingSupportData.requirements.specificModifications,
        };

      case "@[Injury And Accident Injury]":
        return {
          "Musculoskeletal Injuries": {
            Fractures:
              injuryAndAccidentData.injuryTypes.musculoskeletalInjuries
                .fractures,
            "Sprains and Strains":
              injuryAndAccidentData.injuryTypes.musculoskeletalInjuries
                .sprainsAndStrains,
            Dislocations:
              injuryAndAccidentData.injuryTypes.musculoskeletalInjuries
                .dislocations,
            Whiplash:
              injuryAndAccidentData.injuryTypes.musculoskeletalInjuries
                .whiplash,
          },
          "Soft Tissue Injuries": {
            Bruises:
              injuryAndAccidentData.injuryTypes.softTissueInjuries.bruises,
            "Lacerations and Cuts":
              injuryAndAccidentData.injuryTypes.softTissueInjuries
                .lacerationsAndCuts,
            "Tendon or Ligament Tears":
              injuryAndAccidentData.injuryTypes.softTissueInjuries
                .tendonOrLigamentTears,
          },
          "Head Injuries": {
            Concussions:
              injuryAndAccidentData.injuryTypes.headInjuries.concussions,
            "Scalp Lacerations":
              injuryAndAccidentData.injuryTypes.headInjuries.scalpLacerations,
          },
          "Back and Spinal Injuries": {
            "Herniated Discs":
              injuryAndAccidentData.injuryTypes.backAndSpinalInjuries
                .herniatedDiscs,
            "Spinal Fractures":
              injuryAndAccidentData.injuryTypes.backAndSpinalInjuries
                .spinalFractures,
          },
          "Burns and Scalds": {
            "Thermal Burns":
              injuryAndAccidentData.injuryTypes.burnsAndScalds.thermalBurns,
            "Chemical Burns":
              injuryAndAccidentData.injuryTypes.burnsAndScalds.chemicalBurns,
          },
          "Eye Injuries": {
            "Corneal Abrasions":
              injuryAndAccidentData.injuryTypes.eyeInjuries.cornealAbrasions,
            "Foreign Bodies":
              injuryAndAccidentData.injuryTypes.eyeInjuries.foreignBodies,
          },
          "Psychological Impact": {
            "Acute Stress Reaction":
              injuryAndAccidentData.injuryTypes.psychologicalImpact
                .acuteStressReaction,
            PTSD: injuryAndAccidentData.injuryTypes.psychologicalImpact.ptsd,
          },
        };

      case "@[Injury And Accident Instructions]":
        return {
          "Musculoskeletal Care": {
            "Fracture Management":
              injuryAndAccidentData.injuryinstruction.musculoskeletalInjuries
                .fractures.instructions,
            "Sprain and Strain Care":
              injuryAndAccidentData.injuryinstruction.musculoskeletalInjuries
                .sprainsAndStrains.instructions,
            "Dislocation Recovery":
              injuryAndAccidentData.injuryinstruction.musculoskeletalInjuries
                .dislocations.instructions,
            "Whiplash Treatment":
              injuryAndAccidentData.injuryinstruction.musculoskeletalInjuries
                .whiplash.instructions,
          },
          "Soft Tissue Care": {
            "Bruise Management":
              injuryAndAccidentData.injuryinstruction.softTissueInjuries.bruises
                .instructions,
            "Wound Care":
              injuryAndAccidentData.injuryinstruction.softTissueInjuries
                .lacerationsAndCuts.instructions,
            "Tendon and Ligament Care":
              injuryAndAccidentData.injuryinstruction.softTissueInjuries
                .tendonOrLigamentTears.instructions,
          },
          "Head Injury Care": {
            "Concussion Management":
              injuryAndAccidentData.injuryinstruction.headInjuries.concussions
                .instructions,
            "Scalp Wound Care":
              injuryAndAccidentData.injuryinstruction.headInjuries
                .scalpLacerations.instructions,
          },
          "Spinal Care": {
            "Disc Injury Management":
              injuryAndAccidentData.injuryinstruction.backAndSpinalInjuries
                .herniatedDiscs.instructions,
            "Spinal Fracture Care":
              injuryAndAccidentData.injuryinstruction.backAndSpinalInjuries
                .spinalFractures.instructions,
          },
          "Burn Care": {
            "Thermal Burn Treatment":
              injuryAndAccidentData.injuryinstruction.burnsAndScalds
                .thermalBurns.instructions,
            "Chemical Burn Treatment":
              injuryAndAccidentData.injuryinstruction.burnsAndScalds
                .chemicalBurns.instructions,
          },
          "Eye Care": {
            "Corneal Injury Care":
              injuryAndAccidentData.injuryinstruction.eyeInjuries
                .cornealAbrasions.instructions,
            "Foreign Body Management":
              injuryAndAccidentData.injuryinstruction.eyeInjuries.foreignBodies
                .instructions,
          },
          "Psychological Care": {
            "Acute Stress Management":
              injuryAndAccidentData.injuryinstruction.psychologicalImpact
                .acuteStressReaction.instructions,
            "PTSD Management":
              injuryAndAccidentData.injuryinstruction.psychologicalImpact.ptsd
                .instructions,
          },
        };

      case "@[Injury And Accident Duration]":
        return {
          "Recovery Timeline": {
            Weeks: injuryAndAccidentData.duration.weeks,
            Months: injuryAndAccidentData.duration.months,
          },
        };

      case "@[Injury And Accident Purpose]":
        return {
          "Documentation Purpose":
            injuryAndAccidentData.injuryAndAccidentPurpose.purpose,
        };

      case "@[General Recovery Recommendations]":
        return {
          "Nutrition and Hydration":
            injuryAndAccidentData.generalRecoveryRecommendations
              .dietAndHydration.instructions,
          "Medical Follow-up":
            injuryAndAccidentData.generalRecoveryRecommendations.followUpCare
              .instructions,
          "Activity Resumption":
            injuryAndAccidentData.generalRecoveryRecommendations
              .gradualResumptionOfActivities.instructions,
          "Pain Management Guidelines":
            injuryAndAccidentData.generalRecoveryRecommendations.painManagement
              .instructions,
        };

      case "@[Medical Exemption Conditions]":
        return {
          "Exemption Qualifying Conditions":
            medicalExemptionConditionsData.medicalExemption.Conditions,
        };

      case "@[Medical Exemption Reasons]":
        return medicalExemptionConditionsData.medicalExemptionReasons;

      default:
        return null;
    }
  };

  return {
    getRelevantData,
  };
};
