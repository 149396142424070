<template>
  <div v-if="!isLoggedIn">
    <div class="relative overflow-hidden h-screen">
      <img
        src="@/assets/images/authImage.jpg"
        class="absolute h-full w-full object-cover"
      />
      <div class="inset-0 bg-black opacity-40 absolute"></div>

      <div
        class="
          container
          mx-auto
          px-6
          md:px-12
          relative
          z-10
          flex
          items-center
          py-32
          xl:py-40
        "
      >
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
          <span class="font-bold uppercase text-lightOrange"> Welcome </span>
          <h1
            class="font-bold text-6xl sm:text-7xl text-white leading-tight mt-4"
          >
            eHarleyStreet
            <br />
            Strategic Management System
          </h1>
          <router-link :to="{ name: 'gu-login' }"
            ><button
              class="
                transition
                duration-300
                ease-in-out
                block
                bg-lightGreen
                hover:bg-teal
                py-3
                px-4
                rounded-lg
                text-lg text-white
                font-bold
                uppercase
                mt-10
              "
            >
              Login
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CLEANER, MANAGER, SUPER_ADMIN, HQ, RE, MO } from "@/dataObject/roles.js";
import store from "@/store/index.js";
export default {
  async beforeRouteEnter(to, from, next) {
    // Reroute path to manager if logged in is HQ
    if (store.getters["auth/userRoleId"] == HQ) {
      // Redirect to Practice select when no practice stored in local storage
      // Another part of this logic located in 'rules.js'

      // if (
      //   store.getters["auth/getPractice"] &&
      //   !localStorage.getItem("practice")
      // ) {
      //   next("practiceSelect");
      // } else {
      // next("/hq");
      // }
      next("/hq");
      return;
    }
    if (store.getters["auth/userRoleId"] == RE) {
      next("/re");
      return;
    }
    // Reroute path to cleaner if logged in is Cleaner
    if (store.getters["auth/userRoleId"] == CLEANER) {
      if (
        store.getters["auth/getPractice"] &&
        !localStorage.getItem("practice")
      ) {
        next("practiceSelect");
      } else {
        next("/cs");
      }
      return;
    }
    // Reroute path to manager if logged in is Manager
    if (store.getters["auth/userRoleId"] == MANAGER) {
      if (
        store.getters["auth/getPractice"] &&
        !localStorage.getItem("practice")
      ) {
        next("practiceSelect");
      } else {
        next("/ma");
      }
      return;
    }
    // Reroute path to super-admin if logged in is Super-Admin
    if (store.getters["auth/userRoleId"] == SUPER_ADMIN) {
      if (
        store.getters["auth/getPractice"] &&
        !localStorage.getItem("practice")
      ) {
        next("practiceSelect");
      } else {
        next("/sa");
      }
      return;
    }
    if (!!store.getters["auth/userIsGeneric"]) {
      next("/user");
      return;
    }
    if (store.getters["auth/userRoleId"] == MO ) {
      // Redirect to Practice select when no practice stored in local storage
      // Another part of this logic located in 'rules.js'

      // if (
      //   store.getters["auth/getPractice"] &&
      //   !localStorage.getItem("practice")
      // ) {
      //   next("practiceSelect");
      // } else {
      // next("/hq");
      // }
      next("/hq");
      return;
    }
    next();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>
