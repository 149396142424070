/** @format */

const medicalExemptionConditionsData = {
  content: {
    data: `This is to certify that [Patient’s Full Name] has been diagnosed with [Medical Exemption Conditions], which qualifies them for a medical exemption under current guidelines. Due to their condition, they are unable to [Medical Exemption Reasons]. This recommendation is made based on clinical evidence and current best practices.  `,
  },
  medicalExemption: {
    Conditions: [
      "Severe Asthma",
      "Immune System Disorders",
      "Diabetes Mellitus",
      "Severe Allergies or Anaphylaxis",
      "Chronic Respiratory Diseases (e.g., COPD, Cystic Fibrosis)",
      "Cancer Under Treatment",
      "Pregnancy (Complicated or High-Risk)",
      "Neurological Disorders (e.g., Epilepsy, Multiple Sclerosis)",
      "Mental Health Conditions (e.g., PTSD, Severe Anxiety)",
      "Cardiovascular Disorders",
      "Disability or Physical Impairments",
      "Hypothyroidism/Myxoedema",
      "Fistula Requiring Medical Appliances",
      "Mental or Cognitive Disabilities",
    ],
  },
  medicalExemptionReasons: {
    "Severe Asthma": [
      "Avoidance of specific environmental exposures (e.g., fumes, allergens).",
    ],
    "Immune System Disorders": [
      "Delayed or contraindicated vaccinations (e.g., live vaccines).",
    ],
    "Diabetes Mellitus": [
      "Inability to participate in fasting protocols or maintain standard work schedules.",
    ],
    "Severe Allergies Or Anaphylaxis": [
      "Mandatory avoidance of allergens (e.g., peanuts, latex, medications).",
    ],
    "Chronic Respiratory Diseases": [
      "Limitation on exposure to public spaces during outbreaks or crowded areas.",
    ],
    "Cancer Under Treatment": [
      "High-altitude travel or extreme physical exertion limitations.",
    ],
    "Pregnancy Complicated Or HighRisk": [
      "Exemption from strenuous activities or exposure to chemicals harmful during pregnancy.",
    ],
    "Neurological Disorders": [
      "Requirement for extended rest periods or flexible schedules to manage symptoms.",
    ],
    " Mental Health Conditions": [
      "Exemption from high-stress environments or activities exacerbating symptoms.",
    ],
    "Cardiovascular Disorders": [
      "Requirement for accessible environments, emergency care access, or specialized accommodations.",
    ],
    " Disability Or Physical Impairments": [
      "Mandatory participation in inaccessible activities or environments.",
    ],
    "Hypothyroidism Or Myxoedema": [
      "Adjustments for temperature-sensitive environments or dietary restrictions.",
    ],
    "Fistula Requiring Medical Appliances": [
      "Requirement for facilities supporting medical appliances or hygiene needs.",
    ],
    "Mental Or Cognitive Disabilities": [
      "Access to specialized support or adjustments in complex or high-pressure settings.",
    ],
  },
};

export default medicalExemptionConditionsData;
