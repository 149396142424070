/** @format */

const pregnancyFitToFlyGestational = {
  content: {
    data: `I am writing to confirm that [Patient’s Full Name], who is currently [Pregnancy Fit To Fly Gestational], has been assessed and is fit to travel by air. Her pregnancy is progressing normally with no complications to date, and she is in good health to undertake air travel. This certification is valid for her planned travel on [flight date]. Should her condition change prior to this date, I recommend a re-assessment. `,
  },
  duration: {
    weeks: [
      "1 week",
      "2 weeks",
      "3 weeks",
      "4 weeks (1 month)",
      "5 weeks",
      "6 weeks",
      "7 weeks",
      "8 weeks (2 months)",
      "9 weeks",
      "10 weeks",
      "11 weeks",
      "12 weeks (3 months)",
    ],
    months: [
      "1 month (4 weeks)",
      "2 months (8 weeks)",
      "3 months (12 weeks)",
      "4 months (16 weeks)",
      "5 months (20 weeks)",
      "6 months (24 weeks)",
      "7 months (28 weeks)",
      "8 months (32 weeks)",
      "9 months (36 weeks)",
    ],
  },
};

export default pregnancyFitToFlyGestational;
