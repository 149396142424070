/** @format */

const disabilityMedicalData = {
  content: {
    data: `I am writing to confirm that [Patient’s Full Name] has been diagnosed with [Disability Medical Conditions]. This condition has a significant impact on their daily life, including [Disability Medical Impacts].
As a result of their condition, [Patient’s Full Name] requires the following accommodations and support: 
    [Disability Medical Accommodations 1, e.g., accessible housing or transport] 
    [Disability Medical Accommodations 2, e.g., assistance with personal care or daily activities] 
    [Disability Medical Accommodations 3, if applicable]. 
    This letter is provided to support their application for [Disability Medical Purpose].  `,
  },
  disabilityMedicalDisorders: {
    musculoskeletalDisorders: {
      conditions: {
        arthritis:
          "Including osteoarthritis and rheumatoid arthritis, leading to joint pain and mobility issues.",
        backPain: "Severe or chronic back conditions affecting movement.",
        spondylosis: "Degeneration of the spine causing pain and stiffness.",
      },
      impacts: {
        arthritis: [
          "Difficulty with walking, standing, or climbing stairs.",
          "Problems with dressing (e.g., buttons, zippers) or grooming due to joint stiffness.",
          "Inability to perform household tasks like cleaning or cooking.",
          "Pain and fatigue reducing independence.",
        ],
        backPain: [
          "Limited ability to sit or stand for extended periods.",
          "Struggles with lifting or carrying items.",
          "Reduced ability to drive or use public transport.",
          "Sleep disruption due to pain.",
        ],
      },
    },
    neurologicalConditions: {
      conditions: {
        multipleSclerosis:
          "Affecting the brain and spinal cord, leading to a range of disabilities.",
        parkinsonsDisease: "A progressive disorder affecting movement.",
        epilepsy: "Characterized by recurrent seizures.",
        motorNeuroneDisease: "A rare condition affecting the brain and nerves.",
      },
      impacts: {
        multipleSclerosis: [
          "Fatigue impacting work or social activities.",
          "Mobility challenges due to muscle weakness or spasticity.",
          "Difficulty with coordination or fine motor tasks.",
          "Cognitive impairments affecting memory and concentration.",
        ],
        parkinsonsDisease: [
          "Tremors and stiffness affecting handwriting, eating, or dressing.",
          "Freezing episodes causing immobility.",
          "Increased risk of falls.",
          "Speech or swallowing difficulties.",
        ],
        epilepsy: [
          "Unpredictable seizures leading to safety concerns.",
          "Restrictions on driving or operating machinery.",
          "Memory issues or fatigue from medication side effects.",
        ],
        motorNeuroneDisease: [
          "Progressive loss of muscle function, limiting movement.",
          "Inability to speak clearly or eat without assistance.",
          "Dependence on carers for basic tasks.",
        ],
      },
    },
    cardiovascularAndRespiratoryDisorders: {
      conditions: {
        heartDisease:
          "Including heart failure and other conditions limiting physical activity.",
        chronicObstructivePulmonaryDisease:
          "A group of lung conditions causing breathing difficulties.",
        asthma: "Severe cases that significantly impact daily life.",
      },
      impacts: {
        heartDisease: [
          "Shortness of breath during simple activities like walking or dressing.",
          "Fatigue preventing extended physical activity.",
          "Dizziness or fainting when exerting oneself.",
        ],
        chronicObstructivePulmonaryDisease: [
          "Breathlessness during basic activities like climbing stairs or carrying items.",
          "Frequent need to rest or use oxygen therapy.",
          "Difficulty cooking or cleaning due to exhaustion.",
        ],
        asthma: [
          "Avoiding activities or environments that trigger symptoms.",
          "Limited outdoor activity due to weather-related triggers.",
          "Frequent hospital visits or medical intervention.",
        ],
      },
    },
    mentalHealthConditions: {
      conditions: {
        depression: "Severe forms affecting daily functioning.",
        anxietyDisorders:
          "Including generalized anxiety disorder and panic disorders.",
        schizophrenia: "A severe long-term mental health condition.",
        bipolarDisorder: "Causing extreme mood swings.",
      },
      impacts: {
        depression: [
          "Inability to maintain employment or social relationships.",
          "Struggles with motivation to complete daily tasks.",
          "Avoidance of public places or social gatherings.",
        ],
        anxietyDisorders: [
          "Frequent feelings of dread or panic in social situations.",
          "Difficulty maintaining focus on tasks.",
          "Inability to engage in everyday activities.",
        ],
        schizophrenia: [
          "Difficulty maintaining focus or handling stress.",
          "Challenges with routine tasks like shopping or paying bills.",
          "Dependence on carers for support during episodes.",
        ],
        bipolarDisorder: [
          "Inability to manage emotional highs and lows.",
          "Difficulty maintaining stable relationships and employment.",
        ],
      },
    },
    cognitiveDevelopmentalDisorders: {
      conditions: {
        dementia:
          "Including Alzheimer’s disease, affecting memory and cognition.",
        autismSpectrumDisorders: "Impacting social interaction and behavior.",
        learningDisabilities:
          "Significant impairments in intellectual functioning.",
      },
      impacts: {
        dementia: [
          "Forgetting appointments, tasks, or safety measures (e.g., turning off appliances).",
          "Wandering or getting lost in familiar places.",
          "Inability to manage finances or medication.",
        ],
        autismSpectrumDisorders: [
          "Sensory sensitivities causing distress in public spaces.",
          "Difficulty communicating needs or preferences.",
          "Challenges with changes in routine or unfamiliar environments.",
        ],
        learningDisabilities: [
          "Difficulty with learning new skills or concepts.",
          "Struggles with academic performance and daily tasks.",
        ],
      },
    },
    sensoryImpairments: {
      conditions: {
        visualImpairments: "Including blindness and partial sight.",
        hearingImpairments: "Including profound deafness.",
      },
      impacts: {
        visualImpairments: [
          "Inability to navigate independently without aids or assistance.",
          "Challenges with reading, using technology, or identifying hazards.",
          "Dependence on others for transport or shopping.",
        ],
        hearingImpairments: [
          "Difficulty with verbal communication or following instructions.",
          "Risk of missing alarms or safety signals.",
          "Struggles with accessing information in non-visual formats.",
        ],
      },
    },
    chronicPainAndFatigueSyndromes: {
      conditions: {
        fibromyalgia: "Causing widespread pain and fatigue.",
        chronicFatigueSyndrome: "Characterized by extreme tiredness.",
      },
      impacts: {
        fibromyalgia: [
          "Persistent fatigue reducing work or social participation.",
          "Pain preventing prolonged physical activity.",
          "Cognitive 'fog' impacting memory or decision-making.",
        ],
        chronicFatigueSyndrome: [
          "Inability to maintain consistent energy levels.",
          "Need for frequent rest or lying down.",
          "Struggles with daily hygiene or meal preparation.",
        ],
      },
    },
    autoimmuneAndSystemicConditions: {
      conditions: {
        lupus: "Causing inflammation and damage to various body tissues.",
        crohnsDisease: "A type of inflammatory bowel disease.",
      },
      impacts: {
        lupus: [
          "Joint pain and swelling limiting movement.",
          "Skin sensitivity to sunlight affecting outdoor activities.",
          "Fatigue and organ complications reducing independence.",
        ],
        crohnsDisease: [
          "Urgent need for toilet access limiting travel or work.",
          "Pain and fatigue causing work absences.",
          "Dietary restrictions impacting social eating.",
        ],
      },
    },
    cancer: {
      conditions: {
        description:
          "Various forms, especially those affecting daily functioning and mobility.",
      },
      impacts: {
        description: [
          "Fatigue and weakness reducing physical activity.",
          "Side effects of treatment (e.g., nausea, immune suppression) affecting daily life.",
          "Mobility limitations due to surgery or pain.",
        ],
      },
    },
    terminalIllnesses: {
      conditions: {
        description: "Conditions with a prognosis of limited life expectancy.",
      },
      impacts: {
        description: [
          "Rapid decline in physical and mental abilities.",
          "Dependence on palliative care for basic needs.",
          "Emotional strain impacting family and carers.",
        ],
      },
    },
  },
  disabilityMedicalAccomodations: {
    mobilityRelatedAccommodations: {
      mobilityAids: [
        "Wheelchairs (manual or electric)",
        "Walking aids (e.g., canes, crutches, rollators)",
        "Orthopedic shoes or braces",
      ],
      homeModifications: [
        "Ramps for wheelchair access",
        "Stairlifts",
        "Accessible bathrooms (e.g., grab rails, walk-in showers, raised toilet seats)",
      ],
      transportationSupport: [
        "Blue Badge for accessible parking",
        "Adapted vehicles or access to public transport assistance schemes",
        "Dial-a-Ride or community transport services",
      ],
      supportForTravel: [
        "Personal assistants or carers for journeys",
        "Mobility scooters for outdoor use",
      ],
    },
    workplaceAccommodations: {
      flexibleWorkingHours: ["Flexible working hours or remote work options."],
      ergonomicOfficeEquipment: [
        "Ergonomic office equipment (e.g., chairs, keyboards)",
      ],
      adjustedTasks: [
        "Adjusted tasks to reduce physical strain or mental stress.",
      ],
      regularBreaks: ["Regular breaks to manage fatigue."],
      accessibleWorkspaces: [
        "Accessible workspaces (e.g., ramps, elevators, wider doorways)",
      ],
      mentalHealthSupport: [
        "Support for mental health (e.g., Employee Assistance Programs, quiet workspaces)",
      ],
      assistiveTechnology: [
        "Speech-to-text software or screen readers for visual or cognitive impairments",
      ],
    },
    housingSupport: {
      accessibleHousing: ["Ground-floor flats or homes with step-free access."],
      financialAssistance: [
        "Housing Benefit or Discretionary Housing Payments to adapt properties.",
      ],
      supportServices: [
        "Social care assistance for cleaning, cooking, or personal care.",
      ],
      specialistAccommodation: [
        "Supported living or care homes for individuals needing intensive assistance.",
      ],
    },
    assistiveTechnology: {
      visualImpairments: [
        "Screen readers (e.g., JAWS, NVDA)",
        "Braille keyboards or books",
        "Magnifying devices",
      ],
      hearingImpairments: [
        "Hearing aids or cochlear implants",
        "Amplified phones or flashing alert systems",
        "Subtitles or sign language interpreters",
      ],
      physicalDisabilities: [
        "Adaptive devices for eating, dressing, or grooming",
        "Voice-activated smart home devices",
      ],
      cognitiveDisabilities: [
        "Reminder systems or wearable devices for medication schedules",
        "Simplified tech interfaces for dementia or learning disabilities",
      ],
    },
    medicalAndTherapeuticSupport: {
      regularPhysiotherapy: [
        "To improve strength, flexibility, or manage chronic pain.",
      ],
      occupationalTherapy: [
        "Training in using mobility aids or adapting homes and tasks.",
      ],
      mentalHealthSupport: [
        "Counseling or therapy (e.g., CBT for anxiety or depression)",
        "Support groups for emotional well-being",
      ],
      specialistCare: [
        "Neurologists, cardiologists, or pain management clinics for condition-specific needs",
      ],
    },
    educationalSupport: {
      individualizedEducationPlans: [
        "Individualized Education Plans (IEPs) for students with disabilities.",
      ],
      learningSupportAssistants: [
        "Access to learning support assistants or tutors.",
      ],
      specialistSchools: [
        "Specialist schools or colleges for severe disabilities.",
      ],
      examAccommodations: ["Extended time in exams or alternate assessments."],
    },
    financialSupport: {
      disabilityBenefits: [
        "Disability benefits such as Personal Independence Payment (PIP), Attendance Allowance, or Employment and Support Allowance (ESA)",
      ],
      carersAllowance: ["Carer’s Allowance for family members providing care."],
      disabledFacilitiesGrants: [
        "Disabled Facilities Grants for home modifications.",
      ],
      freePrescriptions: [
        "Free prescriptions or discounted public transport fares.",
      ],
    },
    socialAndCommunitySupport: {
      carersOrPersonalAssistants: [
        "Access to carers or personal assistants for daily tasks.",
      ],
      dayCenters: ["Day centers offering activities for social interaction."],
      disabilityAdvocacyServices: [
        "Disability advocacy services to help with legal or benefit claims.",
      ],
      respiteCare: [
        "Respite care for families of individuals with disabilities.",
      ],
    },
    accommodationsForSpecificConditions: {
      chronicPainAndFatigueSyndromes: {
        energyManagement:
          "Energy management strategies (e.g., pacing and rest periods).",
        adjustableFurniture: "Adjustable beds or chairs to reduce discomfort.",
      },
      autismSpectrumDisorders: {
        quietSpaces: "Quiet spaces to reduce sensory overload.",
        visualSchedules:
          "Visual schedules or communication boards for routine management.",
      },
      dementia: {
        memoryAids:
          "Memory aids (e.g., labeled cupboards, clocks with large displays).",
        supervision: "Supervision to prevent wandering or accidents.",
      },
      epilepsy: {
        seizureMonitoringDevices: "Seizure monitoring devices.",
        workplaceAndSchoolAccommodations:
          "Workplace and school accommodations for safety.",
      },
    },
    emergencySupport: {
      emergencyHelplines: [
        "Access to 24-hour emergency helplines (e.g., for mental health crises).",
      ],
      medicalAlertBracelets: [
        "Medical alert bracelets to inform responders of conditions.",
      ],
      emergencyEvacuationPlans: [
        "Emergency evacuation plans in homes or workplaces.",
      ],
    },
  },
  disabilityMedicalPurpose: [
    "disability benefits",
    "housing adaptations",
    "workplace adjustments",
  ],
};

export default disabilityMedicalData;
