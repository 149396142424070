/** @format */

const universityCollegeSicknessConditionsData = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] has been under my care and was unwell from [start date] to [end date]. During this time, they were unable to attend their university/college commitments, due to [University & College Sickness Condition]. They are advised to resume their academic responsibilities gradually and to seek further medical assistance if symptoms persist.`,
  },
  universityCollegeSickness: {
    Conditions: [
      "Acute Illnesses",
      "Chronic Conditions",
      "Mental Health Disorders",
      "Injuries",
      "Surgical Procedures",
      "Pregnancy-Related Issues",
      "Contagious Diseases",
      "Severe Allergic Reactions",
      "Neurological Conditions",
      "Autoimmune Disorders",
    ],
  },
};

export default universityCollegeSicknessConditionsData;
