<!-- @format -->

<template>
  <div class="my-2">
    <div
      v-if="isRejectModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <RejectReasonModal
        @submit-modal="submitRejection"
        @close-modal="closeRejectModal"
      />
    </div>
    <div
      v-if="isModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white px-4 pb-2 rounded-md shadow-lg">
        <div class="flex justify-end my-2">
          <button
            class="material-icons bg-red-500 rounded-full text-white w-6"
            @click="isModalVisible = false"
          >
            close_outliend
          </button>
        </div>
        <VideoPlayer
          :src="selectedFile?.name?.includes('Evidence Video') ? videoSrc : ''"
          @close="isModalVisible = false"
        />
      </div>
    </div>
    <div class="flex mb-4 gap-6 items-center">
      <div class="p-2 rounded-lg bg-gray-500 bg-opacity-10">
        <img
          src="@/assets/icons/Certificate.svg"
          class="w-10"
          alt="Certificate"
        />
      </div>
      <div v-if="isLoading">
        <div class="h-6 bg-gray-300 rounded-md mb-2.5"></div>
        <div class="h-4 bg-gray-300 rounded-md w-32"></div>
      </div>
      <div v-else>
        <h1 class="text-2xl font-medium">
          {{ medicalLetter?.data?.medical_letter?.name }}
        </h1>
        <p class="text-textGray">
          {{
            medicalLetter?.data?.first_name +
            " " +
            medicalLetter?.data?.last_name
          }}
        </p>
      </div>
    </div>
    <div v-if="isLoading" class="bg-white px-6 py-4 my-4">
      <div class="h-6 bg-gray-300 rounded-md w-56 mb-2.5"></div>
      <div class="flex gap-2">
        <div class="h-6 bg-gray-300 rounded-md w-32"></div>
        <div class="h-6 bg-gray-300 rounded-md w-32"></div>
      </div>
    </div>
    <div v-else class="rounded-lg mb-6 bg-white">
      <h2 class="text-lg font-normal mx-6 py-4 border-b">
        Certificate Information
      </h2>
      <h2 class="text-lg font-normal px-6 py-4 flex gap-1 items-center">
        Status:
        <div
          v-if="medicalLetter?.data?.status == 0"
          class="text-teal font-bold text-xl"
        >
          In Process
        </div>
        <div
          v-else-if="medicalLetter?.data?.status == 1"
          class="text-teal font-medium text-xl flex justify-between w-full items-center"
        >
          Delivered
          <button
            class="bg-teal px-8 py-2 text-white rounded-lg text-base cursor-pointer"
            @click="
              downloadFile(
                medicalLetter?.data?.meta?.uploaded_medical_letter_url,
                medicalLetter?.data?.medical_letter?.name
              )
            "
          >
            View Certificate
          </button>
        </div>
        <div v-else class="text-red font-medium text-xl">Rejected/Refunded</div>
      </h2>
      <div class="px-6 pb-4 capitalize" v-if="medicalLetter?.data?.status == 2">
        <span class="text-textGray"> Reason:</span>
        {{ medicalLetter?.data?.rejection_reason }}
      </div>
    </div>

    <PatientInformationForm
      :patient="medicalLetter?.data"
      :isLoading="isLoading"
    />
    <MedicalInformation
      :medicalFiles="medicalLetter?.data?.medical_evidence"
      :photoId="medicalLetter?.data?.image"
      :isLoading="isLoading"
      @view-file="handleViewFile"
    />

    <div class="flex mt-4" v-if="medicalLetter?.data?.status == 0">
      <button
        class="border border-teal text-teal font-medium w-1/2 p-3 rounded-md mr-2"
        @click="openRejectModal"
      >
        Reject & Refund
      </button>
      <button
        class="bg-teal text-white w-1/2 font-medium p-3 rounded-md"
        @click="navigate(medicalLetter?.data?.id)"
      >
        Generate Certificate
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import RejectReasonModal from "@/components/newUi/MedicalLetters/RejectReasonModal.vue";
import PatientInformationForm from "@/components/newUi/MedicalLetters/PatientInformationForm.vue";
import MedicalInformation from "@/components/newUi/MedicalLetters/MedicalInformation.vue";
import VideoPlayer from "@/components/newUi/atoms/VideoPlayer.vue";
import useDownloadFile from "@/composables/useDownloadFile";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";

const { downloadFile } = useDownloadFile();
const isLoading = ref(true);
const store = useStore();
const router = useRouter();
const route = useRoute();
const letterId = route.params.letterId;
const medicalLetter = computed(() => {
  return store.getters["PMLModule/getMedicalLetterRequest"];
});

const navigate = (letterId) => {
  router.push({ name: "confirmMedicalLetter", params: { letterId: letterId } });
};

const isModalVisible = ref(false);
const selectedFile = ref(null);
const videoSrc = ref("");

const isRejectModalVisible = ref(false);
const rejectionReason = ref("");

const openRejectModal = () => {
  isRejectModalVisible.value = true;
};

const closeRejectModal = () => {
  isRejectModalVisible.value = false;
  rejectionReason.value = "";
};

const submitRejection = (rejectReason) => {
  rejectionReason.value = rejectReason;

  store.dispatch("PMLModule/rejectMedicalLetterRequest", {
    medical_letter_request: letterId,
    rejection_reason: rejectionReason.value,
  });
  Swal.fire({
    title: `Medical Letter has been rejected/refunded successfully!`,
    iconHtml: `<img src="${SuccessIcon}" />`,
    width: "32em",
    confirmButtonText: "Done",
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "popup-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      confirmButton: "medical-letter-yes-button",
    },
    didClose: () => {
      router.push({ name: "hq-medicalLettersDashboard" });
    },
    showConfirmButton: false,
  });

  closeRejectModal();
};

const handleViewFile = (file) => {
  selectedFile.value = file;
  if (file?.name?.includes("Evidence Video")) {
    isModalVisible.value = true;
    videoSrc.value = file?.url;
  } else {
    downloadFile(file?.url, file?.name);
  }
};
onMounted(() => {
  store
    .dispatch("PMLModule/fetchMedicalLetterRequest", {
      letterId: letterId,
    })
    .then(() => {
      isLoading.value = false;
    });
});
</script>

<style scoped></style>
