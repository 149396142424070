/** @format */

const allergyData = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] has been diagnosed with [Allergy Type].
This condition has been confirmed through [Allergy Testing Method] and presents with the following symptoms when exposed to [Allergy Type]: 
    [Allergy Symptom 1, e.g., difficulty breathing] 
    [Allergy Symptom 2, e.g., rash or swelling] 
    [Allergy Symptom 3, if applicable] 
    To manage this condition effectively, it is imperative that [Patient’s Full Name] avoids exposure to [specific allergens]. They have also been prescribed [specific medication, e.g., adrenaline auto-injector, antihistamines] for emergency use.`,
  },
  AllergyTypes: {
    Types: [
      "Food Allergies",
      "Inhalant Allergies",
      "Insect Allergies",
      "Contact Allergies",
      "Medication Allergies",
      "Environmental Allergies",
      "DrugInduced Allergies",
      "Skin Allergies",
      "Occupational Allergies",
      "Other Rare Allergies",
    ],
  },
  Allergens: {
    FoodAllergies: [
      "Nuts (e.g., peanuts, tree nuts like almonds, walnuts, cashews)",
      "Shellfish (e.g., prawns, crab, lobster)",
      "Fish (e.g., cod, salmon, tuna)",
      "Eggs",
      "Milk and dairy (e.g., lactose intolerance, cow's milk allergy)",
      "Wheat and gluten (including coeliac disease, although technically an autoimmune condition)",
      "Soy",
      "Sesame seeds",
      "Mustard",
      "Lupin (a legume flour used in baked goods)",
      "Sulphites (in wine, dried fruits, etc.)",
      "Celery",
    ],
    InhalantAllergies: [
      "Pollen (hay fever, also called seasonal allergic rhinitis)",
      "Grass pollen",
      "Tree pollen (e.g., birch, oak)",
      "Weed pollen",
      "House dust mites",
      "Animal dander",
      "Cats",
      "Dogs",
      "Horses",
      "Rodents (e.g., mice, guinea pigs)",
      "Mold spores",
      "Latex particles (airborne or contact-related)",
    ],
    InsectAllergies: [
      "Bee stings",
      "Wasp stings",
      "Hornet stings",
      "Ant bites/stings (rare in the UK)",
    ],
    ContactAllergies: [
      "Nickel (common in jewellery and metal objects)",
      "Latex (e.g., gloves, balloons)",
      "Fragrances (in perfumes, skincare products)",
      "Preservatives (e.g., methylisothiazolinone in cosmetics)",
      "Dyes (e.g., hair dye chemicals like PPD)",
      "Rubber accelerators (in gloves, shoes, elastic bands)",
      "Formaldehyde (used in some building materials and fabrics)",
    ],
    MedicationAllergies: [
      "Antibiotics (e.g., penicillin, amoxicillin, cephalosporins)",
      "NSAIDs (e.g., ibuprofen, aspirin)",
      "General anaesthetics (e.g., succinylcholine)",
      "Local anaesthetics (e.g., lidocaine)",
      "Contrast dyes used in imaging scans",
      "Opioids (e.g., codeine, morphine)",
      "Vaccines (e.g., rare allergic reactions to specific components)",
    ],
    EnvironmentalAllergies: [
      "Pollution (e.g., smog, diesel particulates)",
      "Chemicals (e.g., cleaning products, chlorine)",
      "Smoke (e.g., tobacco smoke)",
    ],
    DrugInducedAllergies: [
      "Reactions to specific biologics (e.g., monoclonal antibodies)",
      "Chemotherapy drugs",
      "Blood products (e.g., plasma, IV immunoglobulins)",
    ],
    SkinAllergies: [
      "Atopic dermatitis (eczema)",
      "Urticaria (hives)",
      "Angioedema (swelling under the skin)",
    ],
    OccupationalAllergies: [
      "Flour dust (baker’s asthma)",
      "Latex (healthcare workers)",
      "Industrial chemicals (e.g., formaldehyde, solvents)",
      "Animal proteins (veterinary workers)",
    ],
    OtherRareAllergies: [
      "Water (aquagenic urticaria)",
      "Sunlight (photosensitivity reactions)",
      "Cold temperatures (cold urticaria)",
      "Exercise-induced anaphylaxis",
      "Semen (seminal plasma hypersensitivity)",
    ],
  },
  AllergySymptoms: {
    FoodAllergies: {
      "Mild To Moderate Symptoms": [
        "Itchy mouth, lips, or throat",
        "Swelling of lips, face, tongue, or throat (angioedema)",
        "Nausea or vomiting",
        "Abdominal pain or cramps",
        "Diarrhoea",
        "Hives (urticaria)",
        "Itchy rash or eczema flare-ups",
      ],
      "Severe Symptoms": [
        "Difficulty breathing (wheezing, tight chest)",
        "Swelling that obstructs the airway",
        "Rapid pulse or low blood pressure (shock)",
        "Dizziness, fainting, or collapse",
      ],
    },
    InhalantAllergies: {
      "Mild To Moderate Symptoms": [
        "Sneezing",
        "Runny or blocked nose (rhinitis)",
        "Itchy or watery eyes (allergic conjunctivitis)",
        "Itchy throat, ears, or nose",
        "Coughing",
      ],
      "Severe Symptoms": [
        "Asthma-like symptoms: wheezing, shortness of breath",
        "Tight chest",
        "Severe fatigue (from prolonged reactions)",
      ],
    },
    InsectAllergies: {
      "Local Reactions": [
        "Swelling, redness, and pain around the sting/bite site",
      ],
      "Systemic Reactions": [
        "Hives (urticaria) or widespread swelling",
        "Difficulty breathing or swallowing",
        "Dizziness, rapid pulse, or fainting",
        "Severe reactions can lead to anaphylaxis (life-threatening)",
      ],
    },
    ContactAllergies: {
      "Contact Symptoms": [
        "Red, itchy rash",
        "Blisters that may ooze fluid",
        "Swollen, dry, or cracked skin",
        "Burning or stinging sensations at the contact site",
      ],
    },
    MedicationAllergies: {
      "Common Symptoms": [
        "Hives (urticaria) or skin rash",
        "Itchy skin",
        "Swelling (face, lips, or throat)",
        "Nausea, vomiting, or diarrhoea",
      ],
      "Severe Symptoms": [
        "Difficulty breathing",
        "Drop in blood pressure (shock)",
        "Anaphylaxis",
      ],
    },
    EnvironmentalAllergies: {
      "Respiratory Symptoms": [
        "Coughing, sneezing",
        "Runny or congested nose",
        "Wheezing or shortness of breath",
      ],
      "Skin Symptoms": ["Itchy rash, dryness, or flare-ups of eczema"],
    },
    SkinAllergies: {
      "Eczema Symptoms": [
        "Dry, cracked, itchy, and inflamed skin",
        "Oozing and crusting sores in severe cases",
      ],
      "Hives Symptoms": [
        "Red, raised, itchy welts on the skin",
        "Swelling that moves around the body",
      ],
      "Angioedema Symptoms": [
        "Deep swelling under the skin (often around the lips, eyes, hands, or throat)",
        "Can be painful or itchy",
      ],
    },
    OccupationalAllergies: {
      "Workplace Symptoms": [
        "Respiratory issues (similar to inhalant allergies)",
        "Skin reactions (e.g., contact dermatitis)",
        "Coughing or wheezing when exposed to allergens at work",
      ],
    },
    RareAllergies: {
      "Water Allergy Symptoms": ["Itchy hives or rash after water exposure"],
      "Cold Allergy Symptoms": [
        "Red, itchy hives triggered by cold exposure",
        "Swelling of hands, lips, or face in cold weather",
      ],
      "Exercise Induced Allergy Symptoms": [
        "Hives, swelling, wheezing, or anaphylaxis after physical activity",
      ],
      "Sunlight Allergy Symptoms": [
        "Itchy rash, blisters, or swelling on sun-exposed skin",
      ],
    },
    GeneralAnaphylaxisSymptoms: [
      "Difficulty breathing (airway swelling, wheezing)",
      "Rapid or weak pulse",
      "Low blood pressure",
      "Dizziness, fainting, or loss of consciousness",
      "Swollen tongue or throat (blocking the airway)",
      "Hives, flushing, or widespread itching",
      "Nausea, vomiting, or diarrhoea",
    ],
  },
  AllergyTestingMethods: [
    "Skin prick tests",
    "Blood tests (IgE tests)",
    "Elimination diets",
  ],
};

export default allergyData;
