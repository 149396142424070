/** @format */

import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent } from "vue";

import rules from "@/rules";
import guRoutes from "./partials/guRoutes";
import csRoutes from "./partials/csRoutes";
import hqRoutes from "./partials/hqRoutes";
import reRoutes from "./partials/reRoutes";
import maRoutes from "./partials/maRoutes";
import saRoutes from "./partials/saRoutes";
import genericUserRoutes from "./partials/generalUserRoutes";

//Home page (User not logged in)
const homepage = () => import("@/pages/homepage.vue");

// Guest user page (Login/Forget/Reset screen)
const practiceSelect = () => import("@/pages/practiceSelect.vue");

// Guest user page (Login/Forget/Reset screen)
const guContainer = defineAsyncComponent({
  loader: () => import("@/containers/guContainer.vue"),
});
// Cleaner page
const csContainer = defineAsyncComponent({
  loader: () => import("@/containers/csContainer.vue"),
});
// HQ Page
const hqContainer = defineAsyncComponent({
  loader: () => import("@/containers/hqContainer.vue"),
});
// RE Page
const reContainer = defineAsyncComponent({
  loader: () => import("@/containers/reContainer.vue"),
});
// Manager Page
const maContainer = defineAsyncComponent({
  loader: () => import("@/containers/maContainer.vue"),
});
// Super admin page
const saContainer = defineAsyncComponent({
  loader: () => import("@/containers/saContainer.vue"),
});
const generalUserComponent = defineAsyncComponent({
  loader: () => import("@/containers/generalUserLayout.vue"),
});
const submitSurveyComponent = defineAsyncComponent({
  loader: () =>
    import(
      "@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/SubmitSurvey.vue"
    ),
});

// Used for guest routes (to display Login/Forget/Reset page)
const GenericRouterView = () => import("@/pages/genericRouterView.vue");

// All
const catchAll = () => import("@/pages/NotFound.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Home Page (Splash Screen)
    {
      path: "",
      name: "homepage",
      component: homepage,
      meta: { layout: guContainer },
    },
    // Guest User (Login/Forget/Reset Password)
    {
      path: "/",
      name: "gu",
      component: GenericRouterView,
      meta: { layout: guContainer },
      beforeEnter: rules.isGuest,
      children: guRoutes,
    },
    // Practice Select
    {
      path: "/practiceSelect",
      name: "practice-select",
      component: practiceSelect,
      meta: { layout: guContainer },
      beforeEnter: rules.isLoggedIn,
    },
    //Cleaner
    {
      path: "/cs",
      name: "cs",
      component: GenericRouterView,
      meta: { layout: csContainer },
      beforeEnter: rules.isCleaner,
      redirect: "/cs/dashboard",
      children: csRoutes,
    },
    // HQ
    {
      path: "/hq",
      name: "hq",
      component: GenericRouterView,
      meta: { layout: hqContainer, role: "hq" },
      beforeEnter: (to, from, next) => {
        if (rules.isHQ) {
          next();
        } else {
          next("/"); // Redirect to an appropriate route if unauthorized
        }
      },
      redirect: "/hq/dashboard",
      children: hqRoutes,
    },
    // RE
    {
      path: "/re",
      name: "re",
      component: GenericRouterView,
      meta: { layout: reContainer },
      beforeEnter: rules.isRE,
      redirect: "/re/vacanciesCandidates",
      children: reRoutes,
    },
    // Manager
    {
      path: "/ma",
      name: "ma",
      component: GenericRouterView,
      meta: { layout: maContainer },
      beforeEnter: rules.isManager,
      redirect: "/ma/dashboard",
      children: maRoutes,
    },
    // MO
    {
      path: "/hq",
      name: "mo",
      component: GenericRouterView,
      meta: { layout: hqContainer, role: "mo" },
      beforeEnter: (to, from, next) => {
        if (rules.isMO) {
          next();
        } else {
          next("/"); // Redirect to an appropriate route if unauthorized
        }
      },
      redirect: "/hq/dashboard",
      children: hqRoutes,
    },
    // Super Admin
    {
      path: "/sa",
      name: "sa",
      component: GenericRouterView,
      meta: { layout: saContainer },
      beforeEnter: rules.isSuperAdmin,
      redirect: "/sa/manage-practices",
      children: saRoutes,
    },
    // General User
    {
      path: "/user",
      name: "     ",
      component: GenericRouterView,
      meta: { layout: generalUserComponent },
      beforeEnter: rules.isGenericUser,
      redirect: "/user/userDetails/",
      children: genericUserRoutes,
    },
    //Catch other links
    {
      path: "/:catchAll(.*)",
      component: catchAll,
    },
    {
      path: "/survey-submission/practice/:practiceId/survey/:surveyId/instance/:instanceId",
      name: "suveySubmission",
      component: submitSurveyComponent,
    },
  ],
});

router.afterEach((to) => {
  if (to.name != "gu-login" && to.name != "homepage") {
    localStorage.setItem("LS_ROUTE_KEY", to.name);
  }
});

export default router;
