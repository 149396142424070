/** @format */

const travelWithMedication = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] is under my care and is prescribed the following medication(s) for the management of [Travel with Medication Condition]: 
    Medication Name 1: [Dose and frequency Free Text] 
    Medication Name 2: [Dose and frequency Free Text] 
    [Patient’s Full Name] is required to carry these medications with them during their travels, including in their hand luggage, to ensure continuity of treatment. Where applicable, they may also require [Travel with Medication Items]. Please allow them to carry these items as necessary.`,
  },
  conditions: [
    "Chronic Pain Conditions",

    "Attention Deficit Hyperactivity Disorder (ADHD)",

    "Anxiety and Sleep Disorders",

    "Epilepsy",

    "Hormonal Disorders",

    "Autoimmune and Inflammatory Conditions",

    "Mental Health Conditions",

    "Respiratory Conditions",
  ],

  items: {
    medications: [
      "Tablets or capsules (e.g., painkillers, antibiotics, antiepileptics)",
      "Liquid medications (e.g., cough syrups, antacids)",
      "Injectable medications (e.g., insulin, biologics)",
      "Inhalers (e.g., asthma inhalers)",
      "Patches (e.g., nicotine patches, pain relief patches)",
    ],

    medicalDevicesAndEquipment: [
      "Insulin pumps or continuous glucose monitors (CGMs)",
      "Nebulizers for respiratory treatments",
      "CPAP machines for sleep apnea",
      "Blood pressure monitors",
      "Blood glucose meters and test strips",
    ],

    administrationItems: [
      "Needles and syringes (e.g., for insulin or other injectable medications)",
      "Auto-injectors (e.g., EpiPens for severe allergies)",
      "Alcohol swabs for injections",
      "Spacer devices for inhalers",
      "Pill organizers",
    ],

    storageAndCare: [
      "Medication cooling packs or insulated bags for temperature-sensitive items",
      "Sharps disposal containers for used needles",
      "Backup batteries or power supplies for medical devices",
    ],

    documentation: [
      "Original prescription documents",
      "Medication schedules or dosage instructions",
      "Emergency contact details for their doctor or healthcare provider",
    ],
  },
};

export default travelWithMedication;
