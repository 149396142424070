/** @format */

const gymHealthClubCancellationConditions = {
  content: {
    data: `This is to confirm that [Patient’s Full Name] has been under my care for [Gym & Health Club Cancellation Conditions] and, as part of their recovery plan, I have advised that they refrain from physical activities such as gym workouts. Due to their current medical condition, it is necessary for [Patient’s Full Name] to cancel their gym or health club membership to avoid exacerbating their symptoms. This advice is in place for the foreseeable future.`,
  },

  conditions: {
    musculoskeletalInjuries: [
      "Fractures",
      "Severe sprains",
      "Chronic back pain",
    ],
    cardiovascularConditions: [
      "Recent heart attacks",
      "Uncontrolled hypertension",
      "Other heart-related issues",
    ],
    respiratoryDisorders: [
      "Severe asthma",
      "Chronic obstructive pulmonary disease (COPD)",
    ],
    neurologicalDisorders: ["Multiple sclerosis", "Parkinson’s disease"],
    mentalHealthIssues: [
      "Severe anxiety",
      "Depression",
      "Other mental health conditions",
    ],
    postSurgicalRecovery: ["Recovery periods following surgery"],
    chronicIllnesses: [
      "Advanced diabetes with complications",
      "Other long-term health conditions",
    ],
  },
};

export default gymHealthClubCancellationConditions;
